<footer class="text-center text-lg-start">
    <section class="d-flex justify-content-center justify-content-lg-between p-4
        border-bottom">
        <div class="me-5 d-none d-lg-block">
            <span>Contáctanos en nuestras redes sociales:</span>
        </div>
        <div class="me-4 d-md-block d-lg-none">
            <span>Contáctanos:</span>
        </div>
        <div>
            <a target="_blank" title="@cantusoficial" href="{{instagram}}" class="me-2 text-reset align-left" role="button">
                <bi name="instagram" class="config-icon icon-small"></bi>
            </a>
            <a target="_blank" title="@cantsustyle" href="{{facebook}}" class="me-2 text-reset align-left" role="button">
                <bi name="facebook" class="config-icon icon-small"></bi>
            </a>
            <!-- <a 
            target="_blank"
            href="https://api.whatsapp.com/send?phone={{contact}}"
            class="me-4 text-reset align-left display-phone"
            >
                <bi name="whatsapp" class="config-icon icon-small"></bi> 
            </a> -->
        </div>
    </section>
    <a target="_blank" title="Contáctanos por WhatsApp" href="https://api.whatsapp.com/send?phone={{contact}}" class="global-contact-us text-reset">
        <bi name="whatsapp" class="config-icon"></bi>
    </a>
    <section class="">
        <div class="container text-center text-md-start mt-5">
            <!-- Grid row -->
            <div class="row mt-3">
                <!-- Grid column -->
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <!-- Content -->
                    <b class="text-uppercase fw-bold mb-4">
                        <i class="fas fa-gem me-3"></i>Cantsu
                    </b>
                    <a [routerLink]="['/home']" [routerLinkActive]="['active']">
                        <img src="/assets/img/logo.png" width="235px" height="90px" class="home-logo" alt="Logo Cantsu" title="Home Cantsu">
                    </a>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <!-- <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <h6 class="text-uppercase fw-bold mb-4">
                        Products
                    </h6>
                    <p>
                        <a href="#!" class="text-reset">Angular</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">React</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">Vue</a>
                    </p>
                    <p>
                        <a href="#!" class="text-reset">Laravel</a>
                    </p>
                </div> -->
                <!-- Grid column -->


                <!-- Grid column -->
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <!-- Links -->
                    <b class="text-uppercase fw-bold mb-4">
                        <ngbd-modal-contact-us></ngbd-modal-contact-us>
                    </b>
                    <p>
                        <bi name="envelope-fill" class="icon-small align-left"></bi>&nbsp;
                        <span>
                            info@cantsu.com
                        </span>
                    </p>
                    <p>
                        <bi name="phone" class="icon-small align-left"></bi>&nbsp;
                        <span>
                            + 57 {{contactShow}}
                        </span>
                    </p>
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
    </section>
    <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
        © 2023 Copyright:
        <a class="text-reset fw-bold" href="https://cantsu.com/">cantsu.com</a>
    </div>
</footer>