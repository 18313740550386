// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    prductApiUrl: "https://staging-api.cantsu.com/",
    contact: "573112305775",
    contactShow: "311 230 5775",
    intagram: "https://www.instagram.com/cantsuoficial/",
    facebook: "https://www.facebook.com/people/Cantsu-Style/100091588608014/",
    webUrl: "https://staging.cantsu.com/",
    merchantID: "508029",
    accountID: "512321",
    freeDelivery: "150000",
    deliveryPrice: "10000",
    checkoutPayuUrl: "https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
