<div class="header-cart">
    <button type="button" class="btn btn-outline-dark" (click)="closeCart()">Cerrar</button>
    <hr>
    <h6 class="mb-0">Tu Carrito</h6>
    <div class="d-flex justify-content-between">
        <p>Tienes {{cartCount$}} {{haveProductsMessage}} en tu carrito</p>
    </div>
</div>
<div [style.height]='innerHeight' class="product-details">
    <div *ngFor="let product of cartProducts$ | async; let i= index" class="d-flex justify-content-between align-items-center mt-2 p-2 items
        rounded">
        <div class="d-flex flex-row" style="width: auto;">
            <img class="rounded image-product" src="{{product.cart.image}}" alt="{{product.name}}" title="{{product.name}}">
            <div class="ml-2">
                <p style="font-weight: bold;">{{product.name}}</p>
                <div style="position: relative;">
                    <p>Cantidad</p>
                    <div style="
                        position: absolute;
                        top: -3px;
                        left: 70px;" class="quantity-box">
                        <input type="text" name="quantity" class="form-control quantity" disabled="disabled"
                            [value]="product.cart.quantity">
                        <button *ngIf="product.cart.quantity> 1" (click)="decreaseProduct(i)"
                            class="btn quantity-btn quantity-left">-</button>
                        <button *ngIf="product.cart.quantity <5" (click)="increaseProduct(i)"
                            class="btn quantity-btn quantity-right">+</button>
                    </div>
                </div>
                <p>
                    Color {{product.cart.color}}, talla {{product.cart.size}}
                    <br>
                    <span *ngIf="product.cart.quantity != 1" ngbTooltip="Valor por unidad">UV </span>
                    <span *ngIf="product.cart.quantity != 1">
                        <span *ngIf="product.specialPrice == '0'">{{product.price | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                        <del class="special-price" *ngIf="product.specialPrice != '0'">{{product.price | currency : 'USD' : 'symbol' : '1.0-0'}}</del>
                        <span *ngIf="product.specialPrice != '0'"> {{product.specialPrice | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                        <br>
                    </span>
                    Total <span *ngIf="product.specialPrice == '0'">{{calculateTotal(product.price, product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                    <del class="special-price" *ngIf="product.specialPrice != '0'">{{calculateTotal(product.price, product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</del>
                    <span *ngIf="product.specialPrice != '0'"> {{calculateTotal(product.specialPrice, product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                </p>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center">
            <a ngbTooltip="Eliminar producto" (click)="deleteItem(product)" role="button">
                <bi name="trash" class="config-icon icon-small"></bi>
            </a>
        </div>
    </div>
</div>
<div class="footer-cart button-config">
    <hr>
    <p>Envío {{cartDelivery | async | currency : 'USD' : 'symbol' : '1.0-0' }} <span class="about-circle"
            ngbTooltip="Envío gratis a partir de compras superiores a {{freeDelivery | currency : 'USD' : 'symbol' : '1.0-0' }}">?</span>
    </p>
    <p>Total carrito {{cartTotal$ | async | currency : 'USD' : 'symbol' : '1.0-0' }}</p>
    <div ngbTooltip="{{cartCount$ == 0 ? 'Agrega productos a tu carrito' : 'Proceder al pago de tus productos'}}" style="display: inline-block;">
        <button (click)="endShop()" class="btn btn-white" [disabled]="cartCount$ == 0">
            <span> Comprar ahora </span>
            <bi name="cart3" class="icon-small
                align-right"></bi>
        </button>
    </div>
</div>