import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-subscribed',
  templateUrl: './landing-subscribed.component.html',
  styleUrls: ['./landing-subscribed.component.css']
})
export class LandingSubscribedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
