import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { City, Deparment, Locality } from "../models/location";


@Injectable({
    providedIn: 'root'
})
export class LocationService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl
    }

    getDepartments(): Observable<Deparment[]> {
        interface DataResponse {
            data: Deparment[]
        }

        return this._http.get<DataResponse>(this.url + 'departments/v1').pipe(
            map(
                result => <Deparment[]>result.data
            )
        )
    }

    getCities(departmentID: string): Observable<City[]> {
        let queryParams = new HttpParams().append("departmentID", departmentID);

        interface DataResponse {
            data: City[]
        }

        return this._http.get<DataResponse>(this.url + 'cities/v1', {
            params: queryParams
        }).pipe(
            map(
                result => <City[]>result.data
            )
        )
    }

    getLocalities(cityID: string): Observable<Locality[]> {
        let queryParams = new HttpParams().append("cityID", cityID);

        interface DataResponse {
            data: Locality[]
        }

        return this._http.get<DataResponse>(this.url + 'localities/v1', {
            params: queryParams
        }).pipe(
            map(
                result => <Locality[]>result.data
            )
        )
    }
}
