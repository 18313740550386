<div class="d-flex justify-content-center" style="text-align: center;">
    <h1>{{name}}</h1>
</div>
<div class="row" style="margin-top: 20px;">
    <div class="col-sm-6">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <ngb-carousel #carouselProduct class="carousel-dark product-design">
                    <ng-template ngIf="images" *ngFor="let i of images; let index = index"  id="foto-{{index}}" ngbSlide>
                        <div class="picsum-img-wrapper">
                            <img [src]="i" alt="Foto {{index}}" title="Foto {{index}}" />
                        </div>
                    </ng-template>
                </ngb-carousel>
                <div *ngIf="specialPrice != '0'" class="show-discount" ngbTooltip="{{discount.value}}% de descuento">
                    <span *ngIf="discount.type == 'percentage'">
                        {{discount.value}}%
                        OFF
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-6">
        <div class="row">
            <div class="col-xs-4 col-md-6 p-1">
                <h2>Precio</h2>
                <span *ngIf="specialPrice == '0'">{{price | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                <del class="special-price" *ngIf="specialPrice != '0'">{{price | currency : 'USD' : 'symbol' :
                    '1.0-0'}}</del>
                <span *ngIf="specialPrice != '0'"> {{specialPrice | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
            </div>
            <div class="col-xs-4 col-md-6 p-1">
                <h2>Cantidad</h2>
                <div class="quantity-box">
                    <input type="text" name="quantity" class="form-control quantity" disabled="disabled"
                        [value]="quantity">
                    <button *ngIf="quantity> 1" (click)="quantity = quantity - 1"
                        class="btn quantity-btn quantity-left">-</button>
                    <button *ngIf="quantity <5" (click)="quantity= quantity + 1"
                        class="btn quantity-btn quantity-right">+</button>
                    <p class="desc-sizes" *ngIf="quantity == 5">
                        Para compras al por mayor, contáctanos!
                    </p>
                </div>
            </div>
            <div class="col-md-6 select-color">
                <h2>Colores disponibles</h2>
                <div *ngFor="let c of colors" class="form-check form-check-inline" ngbTooltip="{{c.name}}">
                    <input (click)="changeColor(c, carouselProduct)" class="form-check-input" type="radio" name="colorOptions"
                        id="inlineColor{{c.name}}" value="{{c.name}}" [(ngModel)]="colorOptions">                            
                    <label [style.background-color]="c.color" (click)="changeColor(c, carouselProduct)" class="form-check-label color"
                        for="inlineColor{{c.name}}">
                        <div *ngIf="c.colorTwo" class="color-two" [style.border-bottom-color]="c.colorTwo"></div>
                    </label>
                </div>
                <p>
                    {{color}}
                </p>
                <p class="contact-colors">
                    ¿Quieres otro color?, contáctanos!
                </p>
            </div>
            <div class="col-md-6 sizes">
                <h2>Tallas disponibles</h2>
                <div *ngFor="let s of sizes" class="form-check form-check-inline">
                    <input (click)="changeSize(s)" class="form-check-input" type="radio" name="sizeOptions"
                        id="inlineSize{{s}}" value="{{s}}" [(ngModel)]="sizeOptions">
                    <label class="form-check-label size" for="inlineSize{{s}}">{{s}}</label>
                </div>
                <p class="desc-sizes">
                    ¿No sabes tu talla?, mira la última foto
                </p>
            </div>
            <!-- meta for google result search -->
            <meta content="COP" itemprop="priceCurrency">
            <meta content="{{specialPrice != '0' ? specialPrice : price }}.00" itemprop="price">
            <meta itemprop="availability" href="http://schema.org/InStock" content="InStock">
            <meta content="{{productUrl}}" itemprop="url">
            <div class="col-lg-6 col-md-12 col-sm-12 button-config">
                <div ngbTooltip="{{validateAddProductMessage}}" style="display: inline-block; min-width: 230px;">
                    <button [disabled]="disabledAddProduct" (click)="addCart()" class="btn btn-dark">
                        <span> Agregar al carrito </span>
                        <bi name="cart-plus" class="icon-small align-right"></bi>
                    </button>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 button-config">
                <div ngbTooltip="{{validateAddProductMessage}}" style="display: inline-block;">
                    <button [disabled]="disabledAddProduct" (click)="shopNow()" class="btn btn-white">
                        <span> Comprar ahora </span>
                        <bi name="cart3" class="icon-small align-right"></bi>
                    </button>
                </div>
            </div>
            <div class="col-md-12">
                <hr>
                <h2>Descripción</h2>
                <p>
                    {{description}}
                </p>
            </div>
            <div class="col-xs-4 col-md-6" *ngIf="product.detail != null">
                <h2>Detalle</h2>
                <ul>
                    <li *ngFor="let d of product.detail">{{d}}</li>
                </ul>
            </div>
            <div class="col-xs-4 col-md-6" *ngIf="product.recommendations != null">
                <h2>Recomendaciones</h2>
                <ul>
                    <li *ngFor="let r of product.recommendations">{{r}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>