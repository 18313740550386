import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, of } from "rxjs";
import { Product } from "../models/product";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    private cartProducts = new BehaviorSubject<Product[]>([])
    private showCart = new BehaviorSubject<boolean>(false)
    cartProducts$ = this.cartProducts.asObservable()
    showCart$ = this.showCart.asObservable()
    freeDelivery = environment.freeDelivery
    deliveryPrice = environment.deliveryPrice

    private static nameLSCartProducts = 'cart-products'

    cartCount$: Observable<number> = this.cartProducts$.pipe(
        map((cart) => cart.length)
    )

    cartSubTotal$: Observable<number> = this.cartProducts$.pipe(
        map((product) => product.reduce((acc, { subTotalPrice, cart }) => (acc += (Number(subTotalPrice) * cart.quantity)), 0))
    )

    cartIVA$: Observable<number> = this.cartProducts$.pipe(
        map((product) => product.reduce((acc, { iva, cart }) => (acc += (Number(iva) * cart.quantity)), 0))
    )

    cartDelivery$: Observable<number> = this.cartProducts$.pipe(
        map(
            (product) => {
                let total = product.reduce((acc, { price, specialPrice, cart }) => (acc += (((specialPrice != '0') ? Number(specialPrice) : Number(price)) * cart.quantity)), 0)
                if (total > Number(this.freeDelivery)) {
                    return 0
                }

                return Number(this.deliveryPrice)
            }
        )
    )

    cartTotal$: Observable<number> = this.cartProducts$.pipe(
        map(
            (product) => {
                let total = product.reduce((acc, { price, specialPrice, cart }) => (acc += (((specialPrice != '0') ? Number(specialPrice) : Number(price)) * cart.quantity)), 0)
                if (total < Number(this.freeDelivery)) {
                    total += Number(this.deliveryPrice)
                }
                return total
            }
        )
    )

    addCartProduct(product: Product): void {
        this.cartProducts.next([...this.cartProducts.value, product])
        localStorage.setItem(CartService.nameLSCartProducts, JSON.stringify([...this.cartProducts.value]))
        this.getCTFromLocalStorage()
    }

    getCTFromLocalStorage(): void {
        let ct = localStorage.getItem(CartService.nameLSCartProducts)
        if (ct != null) {
            let tmpProducts: Product[]

            tmpProducts = JSON.parse(ct)
            this.cartProducts.next(tmpProducts)
        }
    }

    deleteProduct(productToDelete: Product): void {
        this.cartProducts.next(this.cartProducts.value.filter((product) => product !== productToDelete))
        localStorage.setItem(CartService.nameLSCartProducts, JSON.stringify([...this.cartProducts.value]))
    }

    findProduct(productFind: Product): Product | undefined {
        return this.cartProducts.value.find(
            p => p.pathUrl === productFind.pathUrl
                && p.cart.color === productFind.cart.color
                && p.cart.size === productFind.cart.size
        )
    }

    increaseProduct(i: number): void {
        this.cartProducts.value[i].cart.quantity += 1
        this.cartProducts.next(this.cartProducts.value)
        localStorage.setItem(CartService.nameLSCartProducts, JSON.stringify([...this.cartProducts.value]))
    }

    decreaseProduct(i: number): void {
        this.cartProducts.value[i].cart.quantity -= 1
        this.cartProducts.next(this.cartProducts.value)
        localStorage.setItem(CartService.nameLSCartProducts, JSON.stringify([...this.cartProducts.value]))
    }

    clearProducts(): void {
        localStorage.removeItem(CartService.nameLSCartProducts)
        this.cartProducts.next([])
    }

    openCart(): void {
        this.showCart.next(true)
    }

    closeCart(): void {
        this.showCart.next(false)
    }
}