
<app-header></app-header>
<app-loader></app-loader>
<div id="content-body">
    <div class="core-design">
        <router-outlet></router-outlet>
    </div>
</div>
<div class="core-design">
    <app-footer></app-footer>
</div>
<!-- shpoing cart -->
<app-cart class="{{isCartVisible ? 'collapsed-cart' : ''}}"></app-cart>
