import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { ProductsService } from "../../services/products.service";
import { CartService } from "../../services/cart.service";
import { Product, Cart, Discount } from 'src/app/models/product';
import { SeoService } from 'src/app/services/seo.service';
import { environment } from 'src/environments/environment';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.css'],
    providers: [NgbCarousel],
})
export class ProductComponent implements OnInit {
    category: string = ''
    name: string = ''
    color: string = ''
    colors: any
    sizes: Array<string> = []
    description: string = ''
    price: string = ''
    specialPrice: string = ''
    size: string = ''
    quantity: number = 1
    images: Array<string> = []
    product = {} as Product
    discount = {} as Discount
    disabledAddProduct = true
    validateAddProductMessage = ""
    colorChecked = false
    productUrl = ""
    colorOptions = false
    sizeOptions = false   

    constructor(
        private _seoService: SeoService,
        private _productsService: ProductsService,
        private _cartService: CartService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {
        window.scrollTo(0, 0)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
        this._route.params.subscribe((params: Params) => {
            this.category = params['category']
            this.getProduct(this.category, params['product'])
        });
        this.validateProduct()
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    } 

    getProduct(category: string, product: string) {
        this._productsService.getProduct(category, product).subscribe({
            next: (v) => {
                if (v == null) {
                    this._router.navigate(['/home'])
                    return
                }

                this.product = v
                this.product.category = this.category
                this.name = v.name
                this.colors = v.colors
                this.sizes = v.sizes
                this.description = v.description
                this.price = v.price
                this.specialPrice = v.specialPrice
                this.images = v.colors[0].imgs
                this.discount = v.discount
                this._seoService.setTitle(this.name + ' - ' +  v.subCategory + ' ' + this.category)
                this._seoService.setDescription(this.description)
                this._seoService.setOgImage(this.images[0])
                this.productUrl = environment.webUrl + 'category/' + this.category + '/product/' + this.product.pathUrl
            },
            error: (e) => this._router.navigate(['/home']),
        })
    }

    changeColor(color: any, carousel: NgbCarousel) {
        carousel.select('foto-0')
        this.images = color.imgs
        this.color = color.name
        this.validateProduct()
    }

    changeSize(size: string) {
        this.size = size
        this.validateProduct()
    }

    validateProduct(): boolean {
        let messageError = "Seleccione "

        if (this.emptyColor()) {
            messageError += " un color"
            this.validateAddProductMessage = messageError
            return this.disabledAddProduct
        }

        if (this.emptySize()) {
            messageError += " una talla"
            this.validateAddProductMessage = messageError
            return this.disabledAddProduct
        }

        this.validateAddProductMessage = ""

        this.disabledAddProduct = false
        return this.disabledAddProduct
    }

    emptyColor(): boolean {
        return this.color == ""
    }

    emptySize(): boolean {
        return this.size == ""
    }

    addCart(): void {
        if (this.disabledAddProduct) {
            alert("Debe llenar los campos necesarios")
            return
        }

        this.product.cart = new Cart(
            this.quantity,
            this.size,
            this.color,
            this.images[0]
        )

        let lp = this._cartService.findProduct(this.product)

        if (lp != undefined) {
            alert("Ya tienes el mismo producto en el carrito")
            return
        }

        this._cartService.addCartProduct(<Product>this.product)
        alert("Se agrego de forma correcta")
        this.disabledAddProduct = true
        this.size = ""
        this.color = ""
        this.colorOptions = false
        this.sizeOptions = false
        this.validateProduct()
        window.scrollTo(0, 0)
    }

    shopNow(): void {
        if (this.disabledAddProduct) {
            alert("Debe llenar los campos necesarios")
            return
        }

        this.product.cart = new Cart(
            this.quantity,
            this.size,
            this.color,
            this.images[0]
        )

        let lp = this._cartService.findProduct(this.product)

        if (lp != undefined) {
            alert("Ya tienes el mismo producto en el carrito")
            return
        }

        this._cartService.addCartProduct(<Product>this.product)
        alert("Se agrego de forma correcta")
        this._router.navigate(['/shopping-cart'])
    }
}
