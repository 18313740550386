import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
import { Categories, Category } from "../models/category";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl;
    }

    getCategories(): Observable<Categories> {
        interface DataResponse {
            data: Categories
        }

        return this._http.get<DataResponse>(this.url + 'categories/v1').pipe(
            map(
                result => <Categories>result.data
            )
        )
    }

    getCategory(category: string): Observable<Category> {
        let queryParams = new HttpParams().append("category", category);

        interface DataResponse {
            data: Category
        }

        return this._http.get<DataResponse>(this.url + 'category/v1', {
            params: queryParams
        }).pipe(
            map(
                result => <Category>result.data
            )
        )
    }
}