<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title" style="color: white;">
		Agregar {{name}} al carrto
	</h4>
	<button type="button" class="btn" (click)="activeModal.dismiss('Cross click')" style="color: #fff;">
		<bi name="x-circle" class="icon-small"></bi>
	</button>
</div>
<div class="modal-body">
	<div class="row">
		<div class="col-sm-6">
			<ngb-carousel #carousel class="carousel-dark product-design">
				<ng-template ngIf="images" *ngFor="let i of images; let index = index" id="foto-{{index}}" ngbSlide>
					<div class="picsum-img-wrapper">
						<img [src]="i" alt="Foto {{index}}" title="Foto {{index}}" />
					</div>
				</ng-template>
			</ngb-carousel>
			<div *ngIf="specialPrice != '0'" class="show-discount" ngbTooltip="{{discount.value}}% de descuento">
				<span *ngIf="discount.type == 'percentage'">
					{{discount.value}}%
					OFF
				</span>
			</div>
		</div>
		<div class="col-sm-6">
			<div class="row">
				<div class="col-12 mb-2">
					<h2>Precio</h2>
					<span *ngIf="specialPrice == '0'">{{price | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
					<del class="special-price" *ngIf="specialPrice != '0'">{{price | currency : 'USD' : 'symbol' :
						'1.0-0'}}</del>
					<span *ngIf="specialPrice != '0'"> {{specialPrice | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
				</div>
				<div class="col-12 mb-2">
					<h2>Cantidad</h2>
					<div class="quantity-box">
						<input type="text" name="quantity" class="form-control quantity" disabled="disabled"
							[value]="quantity">
						<button *ngIf="quantity> 1" (click)="quantity = quantity - 1"
							class="btn quantity-btn quantity-left">-</button>
						<button *ngIf="quantity <5" (click)="quantity= quantity + 1"
							class="btn quantity-btn quantity-right">+</button>
						<p class="desc-sizes" *ngIf="quantity == 5">
							Para compras al por mayor, contáctanos!
						</p>
					</div>
				</div>
				<div class="col-12 mb-2 select-color">
					<h2>Colores disponibles</h2>
					<div *ngFor="let c of colors" class="form-check form-check-inline" ngbTooltip="{{c.name}}">
						<input (click)="changeColor(c, carousel)" class="form-check-input" type="radio" name="colorOptions"
							id="inlineColor{{c.name}}" value="{{c.name}}" [(ngModel)]="colorOptions">                            
						<label [style.background-color]="c.color" (click)="changeColor(c, carousel)" class="form-check-label color"
							for="inlineColor{{c.name}}">
							<div *ngIf="c.colorTwo" class="color-two" [style.border-bottom-color]="c.colorTwo"></div>
						</label>
					</div>
					<p>
						{{color}}
					</p>
					<p class="contact-colors">
						¿Quieres otro color?, contáctanos!
					</p>
				</div>
				<div class="col-12 mb-2 sizes">
					<h2>Tallas disponibles</h2>
					<div *ngFor="let s of sizes" class="form-check form-check-inline">
						<input (click)="changeSize(s)" class="form-check-input" type="radio" name="sizeOptions"
							id="inlineSize{{s}}" value="{{s}}" [(ngModel)]="sizeOptions">
						<label class="form-check-label size" for="inlineSize{{s}}">{{s}}</label>
					</div>
					<p class="desc-sizes" >
						¿No sabes tu talla?, mira la última foto
					</p>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer">
	<div ngbTooltip="{{validateAddProductMessage}}">
		<button [disabled]="disabledAddProduct" (click)="addCart()" class="btn btn-white" style="font-family: Arial, Helvetica, sans-serif;">
			<span> Agregar al carrito </span>
		</button>
	</div>
	<button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
</div>