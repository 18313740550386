import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

    constructor(
        private _seoService: SeoService,
    ) { 
        window.scrollTo(0, 100)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    } 
}
