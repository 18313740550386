import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BootstrapIconsModule } from 'ng-bootstrap-icons';
import {
    Instagram,
    Whatsapp,
    Tiktok,
    CartPlus,
    Cart3,
    EnvelopeFill,
    Phone,
    Trash,
    CashCoin,
    CartCheck,
    BoxSeam,
    Truck,
    CheckCircle,
    FilePdf,
    FileEarmarkPerson,
    List,
    XCircle,
    Eye,
    Facebook,
    PinMapFill,
} from 'ng-bootstrap-icons/icons';

const icons = {
    Instagram,
    Whatsapp,
    Tiktok,
    CartPlus,
    Cart3,
    EnvelopeFill,
    Phone,
    Trash,
    CashCoin,
    CartCheck,
    BoxSeam,
    Truck,
    CheckCircle,
    FilePdf,
    FileEarmarkPerson,
    List,
    XCircle,
    Eye,
    Facebook,
    PinMapFill,
};

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        BootstrapIconsModule.pick(icons)
    ],
    exports: [
        BootstrapIconsModule
    ]
})
export class IconsModule { }
