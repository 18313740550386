import { NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {  NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsModule } from "./icons/icons.module";

import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CategoryComponent } from './components/category/category.component';
import { ProductComponent } from './components/product/product.component';
import { LoaderModule } from './components/loader/loader.module';
import { CartComponent } from './components/cart/cart.component';
import { ShoppingCartComponent } from './components/cart/shopping-cart.component';
import { LoaderInterceptor } from './components/interceptor/loader.interceptor';

import { NgbdModalConfig } from "./bootstrap/modal-contact-us";
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ProcessingPersonalDataComponent } from './components/audit/processing-personal-data/processing-personal-data.component';
import { TermsAndConditionsComponent } from './components/audit/terms-and-conditions/terms-and-conditions.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { NgbdModalAddProduct } from './bootstrap/modal-add-product';
import { LandingSubscriberComponent } from './components/landing-subscriber/landing-subscriber.component';
import { LandingSubscribedComponent } from './components/landing-subscribed/landing-subscribed.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        FooterComponent,
        CategoryComponent,
        ProductComponent,
        NgbdModalConfig,
        CartComponent,
        ShoppingCartComponent,
        PhoneMaskDirective,
        ProcessingPersonalDataComponent,
        TermsAndConditionsComponent,
        OrderDetailComponent,
        NgbdModalAddProduct,
        LandingSubscriberComponent,
        LandingSubscribedComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        IconsModule,
        LoaderModule,
        NgbTooltipModule,
        ReactiveFormsModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        Title,
        Meta,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
