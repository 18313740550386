export class Deparment {
    constructor(
        public id: string,
        public department: string,
    ) { }
}

export class City {
    constructor(
        public id: string,
        public city: string,
        public status: boolean,
        public hasLocalities: boolean,
        public departmentID: string,
    ) { }
}

export class Locality {
    constructor(
        public id: string,
        public locality: string,
        public status: boolean,
        public cashPayment: boolean,
        public cityID: string,
    ) { }
}