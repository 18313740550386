<header>
    <div class="container pt-3">
        <h1>¡Gracias por Suscribirte a Cantsu!</h1>
    </div>
</header>
<section class="container">
    <p>Estamos encantados de tenerte en nuestra comunidad. Como agradecimiento, aquí tienes tu código de descuento:</p>

    <div class="code-section pt-4 pb-2">
        <p>DESCUENTO20</p>
    </div>

    <h2>Importante</h2>
    <p>Este cupón es válido solo para un usuario y puede usarse en tu primera compra.</p>
</section>