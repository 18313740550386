<h1 class="center">Detalle del pedido</h1>
<h2 class="center">Referencia {{order.reference}}</h2>
<p><ngb-progressbar [value]="configProgress" type="dark"></ngb-progressbar></p>
<div class="row">
    <div class="col option" [ngClass]="this.orderStatus == 'created' ? 'seleted-option': 'unseleted-option'">
        <bi name="check-circle" ngbTooltip="Pedido Iniciado"></bi>
        <!-- Iniciado -->
    </div>
    <div class="col option" [ngClass]="this.orderStatus == 'paid' ? 'seleted-option': 'unseleted-option'" *ngIf="paymentMethod != 'Efectivo'">
        <bi name="cash-coin" ngbTooltip="Pedido Pagado"></bi>
        <!-- Pagado -->
    </div>
    <div class="col option" [ngClass]="this.orderStatus == 'preparation' ? 'seleted-option': 'unseleted-option'">
        <bi name="box-seam" ngbTooltip="Pedido en preparacion"></bi>
        <!-- Preparacion -->
    </div>
    <div class="col option" [ngClass]="this.orderStatus == 'sent' ? 'seleted-option': 'unseleted-option'">
        <bi name="truck" ngbTooltip="Pedido Enviado"></bi>
        <!-- Enviado -->
    </div>
    <div class="col option" [ngClass]="this.orderStatus == 'delivered' ? 'seleted-option': 'unseleted-option'">
        <bi name="cart-check" ngbTooltip="Pedido Entregado"></bi>
        <!-- Entregado -->
    </div>
</div>
<div style="text-align: center;">
    <h2>{{title}}</h2>
    <p style="text-align: center;" *ngIf="subTitle">{{subTitle}}</p>
</div>
<hr>
<div class="row">
    <div class="col-md-6">
        <div *ngFor="let product of order.orderCart; let i= index" class="d-flex justify-content-between align-items-center mt-2 p-3
            items rounded">
            <div class="d-flex flex-row">
                <img class="rounded image-product" src="{{product.imgUrl}}">
                <div class="ml-2">
                    <p style="font-weight: bold;">{{product.name}}</p>
                    <p>
                        Cantidad {{product.quantity}}<br>
                        Color {{product.color}}, talla
                        {{product.size}} <br>
                        <span *ngIf="product.quantity != 1" ngbTooltip="Valor por unidad">UV </span>
                        <span *ngIf="product.quantity != 1">
                            <span *ngIf="product.specialPrice == '0'">{{product.price | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                            <del class="special-price" *ngIf="product.specialPrice != '0'">{{product.price | currency : 'USD' : 'symbol' : '1.0-0'}}</del>
                            <span *ngIf="product.specialPrice != '0'"> {{product.specialPrice | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                            <br>
                        </span>
                        Total <span *ngIf="product.specialPrice == '0'">{{calculateTotal(product.price, product.quantity) | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                        <del class="special-price" *ngIf="product.specialPrice != '0'">{{calculateTotal(product.price, product.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</del>
                        <span *ngIf="product.specialPrice != '0'"> {{calculateTotal(product.specialPrice, product.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <ngb-accordion #acc="ngbAccordion" class="accordion" activeIds="paymenth-method" [closeOthers]="true">
            <ngb-panel id="personal-data" title="Datos personales">
                <ng-template ngbPanelContent>
                    <!-- Vista con datos del usuario -->
                    <div>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Correo</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.email}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Nombre</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.name}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Apellido</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.lastName}}</span><br>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="delivery-data" title="Datos de entrega">
                <ng-template ngbPanelContent>
                    <!-- Vista con datos de entrega -->
                    <div>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Departamento</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{order.orderAddress.department}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Ciudad</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{order.orderAddress.city}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;" *ngIf="order.orderAddress.localityID != '0'">Localidad</span>
                        <span style="font-size: 16px; margin-top: 0px;"
                            *ngIf="order.orderAddress.localityID != '0'">{{order.orderAddress.locality}}</span><br
                            *ngIf="order.orderAddress.localityID != '0'">
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Direccion</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{order.orderAddress.homeAddress}}</span><br>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="paymenth-method" title="Método de pago">
                <ng-template ngbPanelContent>
                    <div style="display: block;">
                        <div class="form-check mb-4" *ngIf="order.paymentMethod == 'PayU'">
                            <img src="/assets/img/logo_payu.png" style="width: 36px; margin-right: 5px;" alt="payu">
                            <span style="margin-top: 3px;"
                                ngbTooltip="Pago con tu tarjeta preferida, PSE o Efectivo.">PayU</span>
                            <p>Pago con tu tarjeta preferida, PSE o Efectivo.</p>
                        </div>
                        <div class="form-check mb-4" *ngIf="order.paymentMethod == 'Bancolombia'">
                            <img src="/assets/img/bancolombia.png" style="width: 36px; margin-right: 5px;" alt="wompi">
                            <span style="margin-top: 3px;"
                                ngbTooltip="Te llegara un Correo con el link de pago.">Bancolombia</span>
                            <p>Pago por medio de link de pago de Wompi.</p>
                        </div>
                        <div class="form-check mb-4" *ngIf="order.paymentMethod == 'Efectivo'">
                            <bi name="cash-coin" class="icon-small" style="color: green; margin-right: 5px;">
                            </bi>
                            <span style="margin-top: 3px;" ngbTooltip="Pague cuando reciba el producto">Efectivo</span>
                            <p>Pago en efectivo a contraentrega.</p>
                        </div>
                        <div class="mb-1" style="
                        font-size: 12px; 
                        border: 1px solid #fff;
                        border-radius: 10px;
                        padding: 6px;">
                            Una vez se confirme el pago de su producto (si aplica) la entrega se realizará entre 3 a 5
                            días hábiles.
                        </div>
                        <hr>
                        <div style="font-size: 15px;">
                            Envío: {{order.deliveryAmount| currency : 'USD' : 'symbol' : '1.0-0'}} <span class="about-circle" ngbTooltip="Envío gratis a partir de compras superiores a {{freeDelivery | currency : 'USD' : 'symbol' : '1.0-0' }}">?</span><br>
                            Total productos: {{order.subTotal | currency : 'USD' : 'symbol' : '1.0-0'}}<br>
                            Total Iva: {{order.iva | currency : 'USD' : 'symbol' : '1.0-0'}}<br>
                            <b>Total: {{order.total | currency : 'USD' : 'symbol' : '1.0-0'}}</b>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>