<nav class="navbar navbar-expand-md navbar-dark">
    <div class="container-fluid nav-core">
        <a [routerLink]="['/home']" class="home-logo" title="Home Cantsu">
            <img [style.width]="widthLogoHeader" width="235px" height="131px" [style.height]="heightLogoHeader"
                [style.padding-top]="padingLogoheader" [style.padding-bottom]="padingLogoheader"
                src="/assets/img/logo.png" class="logo-header" alt="Logo Cantsu" title="Home Cantsu"
                (click)="collapsed = true">
        </a>
        <img class="colombia-small d-sm-inline-block d-md-none" src="/assets/img/colombia_small.png"
            alt="Pedidos en toda Colombia" title="Pedidos en toda Colombia" ngbTooltip="Pedidos en toda Colombia">
        <a (click)="onCartClicked()" class="nav-link d-sm-inline-block d-md-none"
            tabindex="0" title="Carrito" id="" role="button" (click)="collapsed = true">
            <bi name="cart3" class="config-icon icon-small">
            </bi>
            <span id="cart-count">{{ cartCount$ | async }}</span>
        </a>
        <button [ngClass]="collapsed ? '' : 'button-menu-active'" class="btn button-menu" type="button"
            aria-controls="navbarContent" [attr.aria-expanded]="!collapsed" aria-label="Toggle navigation"
            (click)="collapsed= !collapsed">
            <bi [name]="collapsed ? 'list' : 'x-circle'" class="icon-small d-sm-inline-block d-md-none"></bi>
        </button>
    </div>
    <div class="container-fluid">
        <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
            <ul class="navbar-nav ms-auto">
                <li class="d-none d-md-block">
                    <img class="colombia-small" src="/assets/img/colombia_small.png" alt="Pedidos en toda Colombia"
                        title="Pedidos en toda Colombia" ngbTooltip="Pedidos en toda Colombia">
                </li>
                <li class="nav-item" ngbDropdown>
                    <a [routerLink]="['/home']" class="nav-link" title="inicio" role="button"
                        (click)="collapsed = true"> Inicio </a>
                </li>
                <li class="nav-item" ngbDropdown>
                    <a class="nav-link" title="catálogo" tabindex="0" ngbDropdownToggle id="navbarDropdown1"
                        role="button"> Catálogo </a>
                    <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu">
                        <a *ngFor="let c of categories" ngbDropdownItem href="#" title="{{c.name}}"
                            [routerLink]="['/category', c.pathUrl]" role="button" (click)="collapsed = true">
                            {{c.name}}
                        </a>
                    </div>
                </li>

                <li class="nav-item" ngbDropdown (click)="collapsed = true">
                    <ngbd-modal-contact-us></ngbd-modal-contact-us>
                </li>
                <!-- <li class="nav-item" id="searchHeader" ngbDropdown>
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="¿Qué buscas?" aria-label="¿Qué buscas?"
                            aria-describedby="basic-addon1">
                        <div class="input-group-prepend">
                            <button type="button" class="btn btn-outline-secondary">
                                <bi name="instagram" class="config-icon icon-small"></bi>
                            </button>
                        </div>
                    </div>
                </li> -->
                <li class="nav-item" ngbDropdown style="min-width: 200px;">
                    <a (click)="onCartClicked()"
                        class="nav-link d-none d-md-inline-block" tabindex="0" title="Carrito" id="" role="button"
                        (click)="collapsed = true">
                        <bi name="cart3" class="config-icon icon-small">
                        </bi>
                        <span id="cart-count">{{ cartCount$ | async }}</span>
                    </a>
                    <a [routerLink]="['/catalog-pdf']" title="Catálogo en PDF"
                        class="nav-link" tabindex="0" id="" role="button" (click)="collapsed = true">
                        <bi name="file-earmark-person" class="config-icon icon-small"></bi>
                    </a>
                    <!-- <a target="_blank" href="https://maps.app.goo.gl/Ks7m3E5mk9E7vYBs8"
                        title="Visítanos" class="nav-link" tabindex="0"
                        id="" role="button" (click)="collapsed = true">
                        <bi name="pin-map-fill" class="config-icon icon-small"></bi>
                    </a> -->
                    <a target="_blank" href="https://api.whatsapp.com/send?phone={{contact}}"
                        title="Contáctanos por WhatsApp" class="nav-link" tabindex="0"
                        id="" role="button" (click)="collapsed = true">
                        <bi name="whatsapp" class="config-icon icon-small"></bi>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<div *ngIf="pruebas"
    style="background: red; display: inline-block; padding: 0 5px; font-size: 13px; font-family: Arial, Helvetica, sans-serif;">
    pruebas
</div>