import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
import { Product } from "../models/product";
import { map } from "rxjs";
import { Banner } from "../models/banner";

@Injectable({
    providedIn: 'root'
})
export class BannersService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl
    }

    getBanners(): Observable<Banner[]> {
        interface DataResponse {
            data: Banner[]
        }

        return this._http.get<DataResponse>(this.url + 'banners/v1').pipe(
            map(
                result => <Banner[]>result.data
            )
        )
    }
}