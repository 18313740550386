import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Product as ProductCategory } from '../models/category';
import { Cart, Discount, Product } from 'src/app/models/product';
import { ProductsService } from '../services/products.service';
import { Router } from '@angular/router';
import { CartService } from '../services/cart.service';

@Component({
	selector: 'ngbd-modal-add-product',
	templateUrl: './modal-add-product.html',
    styleUrls: ['./modal-add-product.css'],
    providers: [NgbCarousel],
})
export class NgbdModalAddProduct implements OnInit {
	@Input() public productCategory = {} as ProductCategory

    name: string = ''
    product = {} as Product
    discount = {} as Discount
    images: Array<string> = []
    color: string = ''
    price: string = ''
    specialPrice: string = ''
    quantity: number = 1
    colors: any
    sizes: Array<string> = []
    description: string = ''
    size: string = ''
    colorOptions = false
    sizeOptions = false
    disabledAddProduct = true
    validateAddProductMessage = ""
	
	constructor(
		public activeModal: NgbActiveModal,
		private _productsService: ProductsService,
        private _cartService: CartService,
        private _router: Router,
	) {
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.getProduct(this.productCategory.category, this.productCategory.pathUrl)
        }, 10)
        this.validateProduct()
	}

	getProduct(category: string, product: string) {
        this._productsService.getProduct(category, product).subscribe({
            next: (v) => {
                if (v == null) {
                    this._router.navigate(['/home'])
                    return
                }

                this.product = v
                this.name = v.name
                this.colors = v.colors
                this.sizes = v.sizes
                this.description = v.description
                this.price = v.price
                this.specialPrice = v.specialPrice
                this.images = v.colors[0].imgs
                this.discount = v.discount
            },
            error: (e) => this._router.navigate(['/home']),
        })
    }

	changeColor(color: any, carousel: NgbCarousel) {
        carousel.select('foto-0')
        this.images = color.imgs
        this.color = color.name
        this.validateProduct()
    }

	changeSize(size: string) {
        this.size = size
        this.validateProduct()
    }

	validateProduct(): boolean {
        let messageError = "Seleccione "

        if (this.emptyColor()) {
            messageError += " un color"
            this.validateAddProductMessage = messageError
            return this.disabledAddProduct
        }

        if (this.emptySize()) {
            messageError += " una talla"
            this.validateAddProductMessage = messageError
            return this.disabledAddProduct
        }

        this.validateAddProductMessage = ""

        this.disabledAddProduct = false
        return this.disabledAddProduct
    }

    emptyColor(): boolean {
        return this.color == ""
    }

    emptySize(): boolean {
        return this.size == ""
    }

	addCart(): void {
        if (this.disabledAddProduct) {
            alert("Debe llenar los campos necesarios")
            return
        }

        this.product.cart = new Cart(
            this.quantity,
            this.size,
            this.color,
            this.images[0]
        )

        let lp = this._cartService.findProduct(this.product)

        if (lp != undefined) {
            alert("Ya tienes el mismo producto en el carrito")
            return
        }

        this._cartService.addCartProduct(<Product>this.product)
        alert("Se agrego de forma correcta")
        this.disabledAddProduct = true
        this.size = ""
        this.color = ""
        this.colorOptions = false
        this.sizeOptions = false
        this.validateProduct()
		this.activeModal.dismiss()
    }
}