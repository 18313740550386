<div *ngIf="banners != null || !emptyBanners" class="home-banners" style="max-width: 1820px;">
    <ngb-carousel class="carousel-dark product-design" width="100%">
        <ng-template ngbSlide *ngFor="let banner of banners">
            <div class="picsum-img-wrapper">
                <a [routerLink]="[banner.pathUrl]" [title]="banner.name">
                    <img [src]="showSmallBanners ? banner.imgs.smallUrl : banner.imgs.mediumUrl" width="100%"
                        style="object-fit: cover;" [title]="banner.description" [alt]="banner.description" />
                </a>
            </div>
        </ng-template>
    </ngb-carousel>
</div>
<div class="d-flex justify-content-center">
    <h1>CATEGORÍAS</h1>
</div>
<div class="row categories-container justify-content-md-center">
    <div *ngFor="let c of categories" class="col-xl-2 col-lg-3 col-md-4 col category-design">
        <div class="category-design-internal">
            <a [routerLink]="['/category', c.pathUrl]" title="Prendas {{c.name}}">
                <!-- <div class="d-flex justify-content-center">
                    <img src="{{c.imgUrl}}" alt="Prendas {{c.name}}" title="Prendas {{c.name}}" width="80%"
                        height="auto">
                </div> -->
                <div *ngIf="c.discount.category != undefined" class="show-discount" ngbTooltip="{{c.discount.value}}% de descuento">
                    <span *ngIf="c.discount.type == 'percentage'">
                        {{c.discount.value}}%
                        OFF
                    </span>
                </div>
                <div class="d-flex justify-content-center">
                    <h2>{{c.name}}</h2>
                </div>
            </a>
        </div>
    </div>
</div>
<div *ngFor="let c of categories">
    <!-- TODO cambiar a variable de entorno -->
    <app-category [categorySend]="c.pathUrl" [quantity]="8"></app-category>
</div>