<div class="d-flex justify-content-center">
    <h1>{{categoryName}}</h1>
</div>
<div class="d-flex justify-content-center">
    <h2>{{categoryDesc}}</h2>
</div>
<div class="row products-container justify-content-md-center">
    <div *ngFor="let p of products; let i= index" class="col-xl-3 col-lg-4 col-6 product-design">
        <div class="product-design-internal">
            <a [routerLink]="['/category', categoryPath, 'product', p.pathUrl]" title="{{p.name}}">
                <div class="d-flex justify-content-center">
                    <img src="{{p.img}}" alt="{{p.name}}" width="80%" height="auto"
                        style="height: auto;" title="{{p.name}}" srcset="">
                </div>
                <div *ngIf="p.specialPrice != '0'" class="show-discount" ngbTooltip="{{p.discount.value}}% de descuento">
                    <span *ngIf="p.discount.type == 'percentage'">
                        {{p.discount.value}}%
                        OFF
                    </span>
                </div>
                <div class="d-flex justify-content-center title-product">
                    <h3>{{p.name}}</h3>
                </div>
            </a>
            <div class="row justify-content-md-center">
                <div class="col-12" class="price">
                    <span *ngIf="p.specialPrice == '0'">{{p.price| currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                    <del class="special-price" *ngIf="p.specialPrice != '0'">{{p.price | currency : 'USD' : 'symbol' :
                        '1.0-0'}}</del>
                    <span *ngIf="p.specialPrice != '0'"> {{p.specialPrice | currency : 'USD' : 'symbol' :
                        '1.0-0'}}</span>
                </div>
            </div>
            <div class="row desc-product">
                <div class="col-12">
                    <div class="sizes">
                        <div class="size" *ngFor="let s of p.sizes">
                            {{s}}
                        </div>
                    </div>
                </div>
                <div class="col-12" style="padding-right: 0;">
                    <div *ngFor="let color of p.colors" [style.background-color]="color.color"
                        (click)="changeImage(i, color.url)" (mouseover)="changeImage(i, color.url)" ngbTooltip="{{color.name}}" class="color">
                        <div *ngIf="color.colorTwo" class="color-two" [style.border-bottom-color]="color.colorTwo"></div>
                    </div>
                </div>
                <div class="btn-group col-12" role="group" aria-label="Basic example">
                    <button [routerLink]="['/category', categoryPath, 'product', p.pathUrl]" type="button"
                        class="btn btn-white" ngbTooltip="Ir al producto y ver la descripción completa">
                        <span style="font-family: Arial, Helvetica, sans-serif; display: contents;">Ver Detalle </span>
                        <bi name="eye" class="icon-small align-right" style="display: inline-block;"></bi>
                    </button>
                    <button type="button" class="btn btn-light" style="width: 0; min-width: 60px; color: #434343;"
                        ngbTooltip="Agregar Producto al carrito" (click)="addProduct(p)">
                        <bi name="cart-plus" class="icon-small" style="display: inline-block;"></bi>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 center" *ngIf="showMore">
        <button [routerLink]="['/category', categoryPath]" class="btn btn-white" type="button">
            <span style="font-family: Arial, Helvetica, sans-serif; display: contents; font-size: 1.3rem;">Ver más </span>
        </button>
    </div>
</div>
