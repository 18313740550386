import { Component, OnInit } from '@angular/core';
import { CategoriesService } from "../../services/categories.service";
import { Categories, Category } from "../../models/category";
import { SeoService } from 'src/app/services/seo.service';
import { Banner } from 'src/app/models/banner';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { BannersService } from 'src/app/services/banners.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    categories: Category[] = []
    showSmallBanners = false
    banners: Banner[] = []
    emptyBanners = true
    resizeObservable$!: Observable<Event>
    resizeSubscription$!: Subscription

    constructor(
        private _seoService: SeoService,
        private _categoriesService: CategoriesService,
        private _bannersService: BannersService,
    ) {
        window.scrollTo(0, 0)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
        this.loadBanners()
        this.loadCategories()
        if (window.innerWidth < 800) {
            this.showSmallBanners = true
        }
        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            if (window.innerWidth < 800) {
                this.showSmallBanners = true
            } else {
                this.showSmallBanners = false
            }
        })
    }

    ngOnDestroy(): void {
        this.resizeSubscription$.unsubscribe()
    }

    loadBanners() {
        this._bannersService.getBanners().subscribe({
            next: (v) => {
                this.banners = v
                if (v != null) {
                    this.emptyBanners = false
                }
            },
            error: (e) => console.error(e),
        })
    }

    loadCategories() {
        this._categoriesService.getCategories().subscribe({
            next: (v) => {
                this.categories = v.categories
                this._seoService.setTitle(v.title)
                this._seoService.setDescription(v.description)
                this._seoService.setOgImage(environment.webUrl + 'assets/img/logo.png')
            },
            error: (e) => console.error(e),
        });
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    }
}
