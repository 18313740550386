import { Component, OnInit } from '@angular/core';
import { CartService } from './services/cart.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'cantsu'

    isCartVisible = false
    
    constructor(
        private _cartService: CartService,
    ) {
        this._cartService.showCart$.subscribe((v) => {
            this.isCartVisible = v
        })
    }
}
