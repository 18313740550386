import { Component, EventEmitter, OnInit, Output, HostListener } from '@angular/core';
import { CategoriesService } from "../../services/categories.service";
import { Category } from "../../models/category";
import { CartService } from "src/app/services/cart.service";
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
    collapsed = true
    contact: string
    instagram: string
    cartCount$ = this._cartService.cartCount$
    categories: Category[] = []
    pruebas = !environment.production
    private wasInside = true

    widthLogoHeader = '140px'
    heightLogoHeader = '55px'
    padingLogoheader = '0'

    constructor(
        private _categoriesService: CategoriesService,
        private _cartService: CartService,
    ) {
        this.contact = environment.contact
        this.instagram = environment.intagram

        this._cartService.getCTFromLocalStorage()
    }

    ngOnInit(): void {
        this.loadCategories()
    }

    loadCategories() {
        this._categoriesService.getCategories().subscribe({
            next: (v) => {
                this.categories = v.categories
            },
            error: (e) => console.error(e),
        })
    }

    onCartClicked(): void {
        this._cartService.openCart()
    }

    @HostListener('click')
    clickInside() {
        this.wasInside = true
    }

    @HostListener('document:click')
    clickout() {
        if (!this.wasInside) {
            this.collapsed = true
        }
        this.wasInside = false
    }

    // @HostListener("window:scroll", []) onWindowScroll() {
    //     // do some stuff here when the window is scrolled
    //     const verticalOffset = window.pageYOffset
    //         || document.documentElement.scrollTop
    //         || document.body.scrollTop || 0;

    //     if (verticalOffset > 35) {
    //         this.widthLogoHeader = '140px'
    //         this.heightLogoHeader = '55px'
    //         this.padingLogoheader = '0'
    //     } else {
    //         this.widthLogoHeader = '235px'
    //         this.heightLogoHeader = '131px'
    //         this.padingLogoheader = '20px'
    //     }
    // }
}
