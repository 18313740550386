import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable, map } from "rxjs";
import { GeneralError } from "../models/errors";
import { LandingSubscribeResponse } from "../models/langing-subscribe";

@Injectable({
    providedIn: 'root'
})
export class LandingSubscribeService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl
    }

    subscribe(name: string, email: string): Observable<LandingSubscribeResponse | GeneralError> {
        interface DataResponse {
            data: LandingSubscribeResponse
            error: GeneralError
        }

        return this._http.post<DataResponse>(this.url + 'landing-subscribe/v1', {
            "name": name,
            "email": email,
        }, {
            observe: 'response',
        }).pipe(
            map (
                (result) => {
                    if (result.status == 200) {
                        let tmpDataResponse = {} as LandingSubscribeResponse
                        tmpDataResponse.status = result.status
                        return tmpDataResponse
                    }

                    let error = {} as GeneralError

                    if (result.body != null) {
                        error = result.body.error
                    }

                    return error
                }
            )
        )
    }
}