<div class="overlay" *ngIf="isLoading | async">
    <div class="align">
        <img src="/assets/img/load.gif" class="" alt="">
        <h4>Cargando ...</h4>
        <div class="loadingio-spinner-ball-rmnxglgwo1s">
            <div class="ldio-ebsjhng34m7">
                <div></div>
            </div>
        </div>
    </div>
</div>