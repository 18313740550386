import { Component, OnInit } from '@angular/core';
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    contact: string
    contactShow: string
    instagram: string
    facebook: string

    constructor() {
        this.contact = environment.contact
        this.contactShow = environment.contactShow
        this.instagram = environment.intagram
        this.facebook = environment.facebook
    }

    ngOnInit(): void {
    }

}
