<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="color: white;">Contáctanos</h4>
	</div>
	<div class="modal-body">
		<h5>Horarios de atención</h5>
		<p>Lunes a Viernes 9:00 am a 6:00 pm<br>
			Sabados 9:00 am a 1:00 pm</p>
		<h5>Correo</h5>
		<p>info@cantsu.com</p>
		<div class="row">
			<div class="col-2">
				<a target="_blank" title="@cantsuoficial" href="{{instagram}}" class="nav-link" role="button" style="margin-top: 4px;">
					<bi name="instagram" class="config-icon icon-medium"></bi>
				</a>
			</div>
			<div class="col-10">
				<a target="_blank" title="@cantsuoficial" href="{{instagram}}" class="nav-link" role="button">
					<p>
						<span class="font-bold">Catálogo en Instagram</span><br>
						Visitanos @cantsuoficial
					</p>
				</a>
			</div>
			<div class="col-2">
				<a target="_blank" title="Contáctanos por WhatsApp" href="https://api.whatsapp.com/send?phone={{contact}}"
					[routerLinkActive]="['active']" class="nav-link" role="button" style="margin-top: 4px;">
					<bi name="whatsapp" class="config-icon icon-medium"></bi>
				</a>
			</div>
			<div class="col-10">
				<a target="_blank" title="Contáctanos por WhatsApp" href="https://api.whatsapp.com/send?phone={{contact}}"
					[routerLinkActive]="['active']" class="nav-link" role="button">
					<p>
						<span class="font-bold">Ventas por Whatsapp</span><br>
						Escribenos +57 {{contactShow}}
					</p>
				</a>
			</div>
			<div class="col-2">
				<a target="_blank" title="Visitanos en Facebook" href="{{facebook}}"
					[routerLinkActive]="['active']" class="nav-link" role="button" style="margin-top: 4px;">
					<bi name="facebook" class="config-icon icon-medium"></bi>
				</a>
			</div>
			<div class="col-10">
				<a target="_blank" title="Contáctanos por WhatsApp" href="{{facebook}}"
					[routerLinkActive]="['active']" class="nav-link" role="button">
					<p>
						<span class="font-bold">Visitanos en Facebook</span><br>
						Cantsu Style
					</p>
				</a>
			</div>
			<!-- <div class="col-4">
				<a 
				target="_blank"
				href="{{instagram}}"
				class="nav-link" 
				tabindex="0" 
				id="" 
				role="button"
				> 
					<bi name="instagram" class="config-icon icon-small"></bi>
				</a>
			</div> -->
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Cerrar</button>
	</div>
</ng-template>
<a (click)="open(content)" class="nav-link" tabindex="0" id="" role="button">
	Contáctenos
</a>