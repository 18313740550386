import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CategoriesService } from "../../services/categories.service";
import { Product } from "../../models/category";
import { SeoService } from 'src/app/services/seo.service';
import { NgbdModalAddProduct } from 'src/app/bootstrap/modal-add-product';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
    @Input() categorySend: string = ''
    @Input() quantity: number = 9999
    categoryPath: string = ''
    products: Product[] = []
    categoryName: string = ''
    categoryDesc: string = ''
    showMore: boolean = false

    constructor(
        private _seoService: SeoService,
        private _categoriesService: CategoriesService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _modalService: NgbModal,
    ) {
        window.scrollTo(0, 0)
    }

    ngOnInit(): void {
        if (this.categorySend !== '') {
            this.categoryPath = this.categorySend
            this.loadCategories(this.categorySend)
            return
        }

        this.createLinkForCanonicalURL()
        this._route.params.subscribe((params: Params) => {
            this.categoryPath = params['category']
            this.loadCategories(this.categoryPath)
        })
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    }

    loadCategories(category: string) {
        this._categoriesService.getCategory(category).subscribe({
            next: (v) => {
                if (v == null) {
                    this._router.navigate(['/home'])
                    return
                }

                this.products = v.products
                this.categoryName = v.name
                this.categoryDesc = v.description

                if (this.quantity != 9999) {
                    this.products = v.products.slice(0, this.quantity)
                    this.showMore = true
                } else {
                    this._seoService.setTitle(this.categoryDesc + ' - Cantsu')
                    this._seoService.setDescription(v.longDescription)
                    this._seoService.setOgImage(v.imgUrl)
                }

                this.products.forEach((element: any, index: number) => {
                    this.products[index].img = element.colors[0].url
                })
            },
            error: (e) => this._router.navigate(['/home']),
        })
    }

    changeImage(index: number, url: string) {
        this.products[index].img = url
    }

    addProduct(product: Product): void {
        const modalRef = this._modalService.open(NgbdModalAddProduct, {
            windowClass: 'dark-modal'
        })
        modalRef.componentInstance.productCategory = product
    }
}
