<h3>AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES.</h3>
<p>
    Autorizo el tratamiento de mis datos personales suministrados a la comercializadora CANTSU S.A.S. Sociedad con
    domicilio en la ciudad de Bogotá D.C. e identificado con el NIT: 901638812-1, cómo responsable de la colección, uso
    y protección de tales datos me ha informado que tratara mis datos de conformidad con las siguientes finalidades
    relacionadas con el fin social de la compañía.
</p>
<p>

    I. Cumplir con relaciones legales, comerciales y análisis de consumo. <br>
    II. Participar en campañas publicitarias, recibir información promocional, de marketing y de nuevos productos;<br>
    III. Gestionar el cumplimiento de obligaciones legales, precontractuales, contractuales, poscontractuales,
    tributarias, financieras y/o contables.<br>
    IV. Gestionar las consultas, solicitudes, peticiones, quejas y reclamos relacionados con los titulares de la
    información.<br>
    V. Estudiar y analizar la información entregada para el seguimiento y mejoramiento de los productos.<br>
    VI. Enviar información por parte de la Compañía, vía correo físico y/o electrónico, mensajes de texto (SMS y/o MMS),
    a través de redes sociales como Facebook, Instagram o "WhatsApp" u otras plataformas similares, notificaciones push,
    medios telefónicos o cualquier otro medio de comunicación que la tecnología y la Ley permitan.<br>
    VII. Contactarme para la atención de sugerencias y/o requerimientos que sean presentados por mi parte.<br>
    VIII. Asesoramiento comercial y servicios postventa;<br>
    IX. Validación de la información con el fin de dar cumplimiento a la regulación de Lavado de Activos y Financiación
    del Terrorismo por parte de la Compañía o con terceros contratados para dicho fin.<br>
    X. Estudiar las solicitudes de crédito y de productos financieros.<br>
    XI. Realizar scores crediticios, aplicar herramientas de validación de ingresos, herramientas predictivas de
    ingresos y herramientas para evitar el fraude y, en general, realizar una adecuada gestión del riesgo.<br>
    XII. Adicionalmente la compañía me informó que los datos personales serán tratados de forma segura y podrán ser
    tratados de manera directa o a través de otros responsables y/o encargados, quienes podrán estar domiciliados dentro
    o fuera del territorio Colombiano.<br>
    Me ha sido informado que cualquier información sensible solicitada, relacionada entre otros, con mis datos
    biométricos o datos de salud, podrán no ser respondidos por mí Comercializadora CANTSU SAS, informa que, como
    titular de los datos personales tengo derecho a conocer, actualizar, acceder gratuitamente, rectificar y suprimir
    mis datos, solicitar prueba de esta autorización o revocarla; presentar quejas a la Superintendencia de Industria y
    Comercio por infracciones frente a la normatividad vigente. <br>
    Para efectos de solicitar aclaraciones, presentar quejas o reclamos o solicitar en general cualquier información
    referente al tratamiento de datos personales, la Compañía ha habilitado los siguientes medios: correo
    electrónico: cervicioalciente@cantsu.com; línea nacional 3112305775 o a través de carta dirigida a la Dirección de
    Servicio al Cliente a la Calle 2 sur#72 b-56 en Bogotá D.C."
</p>