import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-processing-personal-data',
    templateUrl: './processing-personal-data.component.html',
    styleUrls: ['./processing-personal-data.component.css']
})
export class ProcessingPersonalDataComponent implements OnInit {

    constructor(
        private _seoService: SeoService,
    ) {
        window.scrollTo(0, 100)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    } 
}
