import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralError } from 'src/app/models/errors';
import { LandingSubscribeService } from 'src/app/services/landing-subscribe.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-landing-subscriber',
    templateUrl: './landing-subscriber.component.html',
    styleUrls: ['./landing-subscriber.component.css']
})
export class LandingSubscriberComponent implements OnInit {
    name = ""
    email = ""

    constructor(
        private _seoService: SeoService,
        private _landingSubscribeService: LandingSubscribeService,
        private _router: Router,
    ) { 
        window.scrollTo(0, 0)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
        this._seoService.setTitle('Cantsu - Suscribirse')
        this._seoService.setDescription('Suscribete')
    }

    subscribe() {
        this._landingSubscribeService.subscribe(this.name, this.email).subscribe({
            next: (v) => {
                if (v.status == 200) {
                    alert("Se registro de forma exitosa.")
                    this._router.navigate(['/thanks-for-subscribe'])
                    return
                }
                let tmpErr = <GeneralError>v
                console.error(tmpErr)
                alert("Ocurrio un error creando la suscripcion, " + tmpErr.detail)
            },
            error: (e) => console.error(e),
        })
    }

}
