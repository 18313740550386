import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Meta, Title } from '@angular/platform-browser'

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(
        @Inject(DOCUMENT) private doc: any,
        private _title: Title,
        private _meta: Meta
    ) {

    }

    createLinkForCanonicalURL() {
        this.destroyLinkForCanonicalURL()
        let link: HTMLLinkElement = this.doc.createElement('link')
        link.setAttribute('rel', 'canonical')
        this.doc.head.appendChild(link)
        link.setAttribute('href', this.doc.URL)
    }

    destroyLinkForCanonicalURL() {
        const els = this.doc.querySelectorAll('link[rel=\'canonical\']')
        for (let i = 0, l = els.length; i < l; i++) {
            const el = els[i]
            el.remove()
        }
    }

    setTitle(title: string): void {
        this._title.setTitle(title)
        this._meta.updateTag({ property: 'og:title', content: title })
    }

    setDescription(description: string): void {
        this._meta.updateTag({ name: 'description', content: description })
        this._meta.updateTag({ property: 'og:description', content: description })
    }

    setOgImage(imgUrl: string): void {
        this._meta.updateTag({ property: 'og:image', content: imgUrl })
        this._meta.updateTag({ property: 'og:type', content: 'product' })
    }
} 