import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailResponse, User } from 'src/app/models/order';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {
    orderID = ''
    configProgress = 0
    configMax = 10
    paymentMethod = ''
    orderStatus = ''
    title = 'Pedido Creado'
    subTitle = 'Pendiente pago'
    freeDelivery = environment.freeDelivery
    user = {} as User
    order = {} as OrderDetailResponse

    constructor(
        private config: NgbProgressbarConfig,
        private _orderService: OrderService,
        private _route: ActivatedRoute,
        private _router: Router,
    ) {
        window.scrollTo(0, 100)
        this.config.max = this.configMax
        this.config.striped = true
        this.config.animated = true
        this.config.type = 'success'
        this.config.height = '20px'
    }

    ngOnInit(): void {
        this.getOrderID()

        this._orderService.getOrderDetail(this.orderID).subscribe({
            next: (v) => {
                this.order = v
                this.user = this.order.user
                this.paymentMethod = this.order.paymentMethod
                this.orderStatus = this.order.status
                this.calculateStatus()
            },
            error: (e) => {
                console.error(e);
                this._router.navigate(['/home'])
            },
        })
    }

    getOrderID(): void {
        this._route.params.subscribe((params: Params) => {
            this.orderID = params['orderID']            
        })

        if (this.orderID != undefined) {
            return
        }

        this._route.queryParams.subscribe((params: Params) => {
            this.orderID = params['referenceCode']
        })

        if (this.orderID != undefined) {
            this._router.navigate(['/order-detail', this.orderID])
            return
        }

        this._router.navigate(['/home'])
    }

    calculateTotal(value: string, quantity: number): string {
        return String(Number(value) * quantity)
    }

    calculateBar(): void {

    }

    calculateStatus(): void {
        switch (this.orderStatus) {
            case 'created':
                this.configProgress = 2
                this.title = 'Pedido Creado'
                switch (this.paymentMethod) {
                    case 'PayU':
                        this.subTitle = 'Pendiente pago, una vez recibamos la confirmación de pago empezaremos a alistar tu pedido'
                        break;
                    case 'Bancolombia':
                        this.subTitle = 'Pendiente pago, pronto recibirás el link de pago'
                        break;
                    case 'Efectivo':
                        this.subTitle = 'Pronto empezaremos a preparar tu pedido'
                        break;
                }
                break;
            case 'paid':
                this.configProgress = 4
                this.title = 'Pedido Pagado'
                this.subTitle = 'Pronto empezaremos a preparar tu pedido'
                break;
            case 'preparation':
                this.configProgress = 6
                if (this.paymentMethod == 'Efectivo') {
                    this.configProgress = 5
                }
                this.title = 'Pedido en Preparación'
                this.subTitle = 'Pronto será enviado'
                break;
            case 'sent':
                this.configProgress = 8
                if (this.paymentMethod == 'Efectivo') {
                    this.configProgress = 7
                }
                this.title = 'Pedido Enviado'
                this.subTitle = 'Pronto llegara tu pedido'
                break;
            case 'delivered':
                this.configProgress = 10
                this.title = 'Pedido Entregado'
                this.subTitle = ''
                break;
        }
    }
}
