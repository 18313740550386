<header>
    <div class="container">
        <h1>Bienvenidos a Cantsu</h1>
    </div>
</header>
<section id="main" class="container">
    <h2>Descubre tu estilo con nuestras prendas únicas</h2>
    <p>En Cantsu, entendemos que cada persona es única y merece ropa que refleje su personalidad. Por eso, nos
        especializamos en buzos personalizados, sudaderas y sacos semi formales que combinan comodidad y estilo.</p>

    <div id="form-section">
        <h3>¡Regístrate y obtén un 20% de descuento!</h3>
        <p>Completa tu registro y recibe un cupón del 20% de descuento en tu primera compra. No te pierdas la
            oportunidad de renovar tu guardarropa con nuestras exclusivas ofertas.</p>

        <form #formSubscribe="ngForm">
            <span class="form-label">
                Nombre
            </span>
            <span *ngIf="fullName.touched && !fullName.valid" class="form-message-error">
                Ingrese un nombre
            </span>
            <div class="input-group mb-3">
                <input [(ngModel)]="name" name="fullName" #fullName="ngModel" class="form-control" type="text"
                    placeholder="Ingrese el nombre" style="max-width: 500px;" required pattern="[a-zA-Z ]+" />
            </div>

            <span class="form-label">
                Correo Electrónico
            </span>
            <span *ngIf="userEmail.touched && !userEmail.valid" class="form-message-error">
                Ingrese un correo válido
            </span>
            <div class="input-group mb-3">
                <input [(ngModel)]="email" name="userEmail" #userEmail="ngModel" type="email" class="form-control"
                    style="max-width: 500px;" required placeholder="Ingrese el correo"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
            </div>
            <span ngbTooltip="{{ !formSubscribe.form.valid ? 'Ingrese los campos obligatorios' : ''}}">
                <button type="submit" class="btn btn-white" [disabled]="!formSubscribe.form.valid"
                    (click)="subscribe()">Registrarse</button>
            </span>
        </form>
    </div>

    <h2>¿Por qué elegir Cantsu?</h2>
    <ul>
        <li><strong>Diseños exclusivos:</strong> Nuestra colección está pensada para hombres y mujeres jóvenes que
            buscan destacar con piezas únicas.</li>
        <li><strong>Calidad garantizada:</strong> Utilizamos materiales de primera calidad para asegurarnos de que cada
            prenda sea duradera y cómoda.</li>
        <li><strong>Personalización:</strong> Da un toque personal a tu look con nuestras opciones de personalización,
            para que cada prenda sea verdaderamente tuya.</li>
    </ul>
</section>