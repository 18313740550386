export class Product {
    constructor(
        public category: string,
        public subCategory: string,
        public name: string,
        public description: string,
        public pathUrl: string,
        public price: string,
        public specialPrice: string,
        public subTotalPrice: string,
        public iva: string,
        public position: string,
        public status: boolean,
        public sizes: string[],
        public colors: Color[],
        public cart: Cart,
        public discount: Discount,
        public detail: string[],
        public recommendations: string[],
    ) { }
}

export class Color {
    constructor(
        public name: string,
        public color: string,
        public imgs: string[],
    ) { }
}

export class Cart {
    constructor(
        public quantity: number,
        public size: string,
        public color: string,
        public image: string,
    ) { }
}

export class Discount {
    constructor(
        public category: string,
        public type: string,
        public value: number,
        public weight: number,
        public status: boolean,
    ) { }
}