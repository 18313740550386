import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { City, Deparment, Locality } from "../models/location";
import { CartService } from "./cart.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Product } from "../models/category";
import { Observable, map } from "rxjs";
import { GeneralError } from "../models/errors";
import { OrderDetailResponse, OrderResponse } from "../models/order";

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    private url: string
    private total: string = "0"
    private subTotal: string = "0"
    private iva: string = "0"
    private delivery: string = "0"
    private cart: { 
        category: string,
        name: string,
        pathUrl: string,
        imgUrl: string,
        size: string,
        color: string,
        quantity: number,
        price: string,
        specialPrice: string,
        subTotalPrice: string,
        iva: string,
        discount: { 
            category: string,
            type: string,
            value: number,
            weight: number,
            status: boolean,
        },
    }[] = []

    


    constructor(
        private _cartService: CartService,
        public _http: HttpClient,
    ) {
        this.url = environment.prductApiUrl;
        this._cartService.cartTotal$.subscribe((total) => this.total = total.toString())
        this._cartService.cartSubTotal$.subscribe((subTotal) => this.subTotal = subTotal.toString())
        this._cartService.cartIVA$.subscribe((iva) => this.iva = iva.toString())
        this._cartService.cartDelivery$.subscribe((delivery) => this.delivery = delivery.toString())
    }

    checkout(user: User, department: Deparment, city: City, locality: Locality, address: string, paymentMethod: string): Observable<OrderResponse | GeneralError> {
        interface DataResponse {
            data: OrderResponse
            error: GeneralError
        }

        this.cart = []
        this._cartService.cartProducts$.subscribe((products) => {
            products.forEach(p => {
                this.cart.push({
                    "category": p.category,
                    "name": p.name,
                    "pathUrl": p.pathUrl,
                    "imgUrl": p.cart.image,
                    "size": p.cart.size,
                    "color": p.cart.color,
                    "quantity": p.cart.quantity,
                    "price": p.price,
                    "specialPrice": p.specialPrice,
                    "subTotalPrice": p.subTotalPrice,
                    "iva": p.iva,
                    "discount": p.discount,
                })
            })
        })
        
        return this._http.post<DataResponse>(this.url + 'checkout/v1', {
            "paymentMethod": paymentMethod,
            "total": this.total,
            "subTotal": this.subTotal,
            "iva": this.iva,
            "deliveryAmount": this.delivery,
            "cart": this.cart,
            "user": {
                "name": user.name,
                "lastName": user.lastName,
                "email": user.email,
                "cc": user.cc.toString(),
                "cellPhone": user.phone
            },
            "homeAddress": {
                "departmentID": department.id,
                "department": department.department,
                "cityID": city.id,
                "city": city.city,
                "localityID": locality.id,
                "locality": locality.locality,
                "homeAddress": address
            }
        }, {
            observe: 'response',
        }).pipe(
            map (
                (result) => {
                    if (result.status == 200) {
                        let tmpDataResponse = {} as OrderResponse
                        if (result.body != null) {
                            tmpDataResponse = result.body.data
                        }
                        tmpDataResponse.status = result.status
                        return tmpDataResponse
                    }

                    let error = {} as GeneralError

                    if (result.body != null) {
                        error = result.body.error
                    }

                    return error
                }
            )
        )
    }

    getOrderDetail(orderID: string) {
        let queryParams = new HttpParams().append("orderID", orderID)

        interface DataResponse {
            data: OrderDetailResponse
        }

        return this._http.get<DataResponse>(this.url + 'order-detail/v1', {
            params: queryParams
        }).pipe(
            map(
                result => <OrderDetailResponse>result.data
            )
        )
    }
}