import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
import { Product } from "../models/product";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl
    }

    getProduct(category: string, product: string): Observable<Product> {
        let queryParams = new HttpParams().appendAll({
            "category": category,
            "product": product,
        })

        interface DataResponse {
            data: Product
        }

        return this._http.get<DataResponse>(this.url + 'product/v1', {
            params: queryParams
        }).pipe(
            map(
                result => <Product>result.data
            )
        )
    }
}