import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import routes
import { HomeComponent } from "./components/home/home.component";
import { CategoryComponent } from "./components/category/category.component";
import { ProductComponent } from "./components/product/product.component";
import { ShoppingCartComponent } from "./components/cart/shopping-cart.component";
import { ProcessingPersonalDataComponent } from './components/audit/processing-personal-data/processing-personal-data.component';
import { TermsAndConditionsComponent } from './components/audit/terms-and-conditions/terms-and-conditions.component';
import { OrderDetailComponent } from './components/order-detail/order-detail.component';
import { CatalogPdfComponent } from './components/catalog-pdf/catalog-pdf.component';
import { LandingSubscriberComponent } from './components/landing-subscriber/landing-subscriber.component';
import { LandingSubscribedComponent } from './components/landing-subscribed/landing-subscribed.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'home', component: HomeComponent },
    { path: 'category/:category', component: CategoryComponent },
    { path: 'category/:category/product/:product', component: ProductComponent },
    { path: 'shopping-cart', component: ShoppingCartComponent },
    { path: 'order-detail', component: OrderDetailComponent },
    { path: 'order-detail/:orderID', component: OrderDetailComponent },
    { path: 'catalog-pdf', component: CatalogPdfComponent },
    { path: 'landing-subscribe', component: LandingSubscriberComponent },
    { path: 'thanks-for-subscribe', component: LandingSubscribedComponent },
    // Audit
    { path: 'processing-personal-data', component: ProcessingPersonalDataComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
    { path: '**', component: HomeComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
