<div class="d-flex justify-content-center">
    <h1>Tu Carrito</h1>
</div>
<div class="d-flex justify-content-center">
    <div class="d-flex justify-content-between">
        <h2>Tienes {{cartCount$}} {{haveProductsMessage}} en tu carrito</h2>
    </div>
</div>
<div class="d-flex justify-content-center">
    <p *ngIf="cartCount$ == 0">
        Agrega productos para continuar con el proceso de compra.
    </p>
</div>
<div class="row justify-content-lg-center">
    <div class="col-lg-4">
        <ngb-accordion #acc="ngbAccordion" class="accordion" activeIds="{{ movil ? '' : 'products'}}" [closeOthers]="true">
            <ngb-panel id="products" title="Tus Productos">
                <ng-template ngbPanelContent>
                    <div *ngFor="let product of cartProducts$ | async; let i= index" class="d-flex justify-content-between align-items-center items rounded">
                        <div class="d-flex flex-row">
                            <img class="rounded image-product" alt="{{product.name}}" title="{{product.name}}"
                                src="{{product.cart.image}}">
                            <div class="ml-2">
                                <p style="font-weight: bold;">{{product.name}}</p>
                                <div style="position: relative;">
                                    <p>Cantidad</p>
                                    <div style="
                                        position: absolute;
                                        top: -3px;
                                        left: 70px;" class="quantity-box">
                                        <input type="text" name="quantity" class="form-control quantity" disabled="disabled"
                                            [value]="product.cart.quantity">
                                        <button *ngIf="product.cart.quantity> 1" (click)="decreaseProduct(i)"
                                            class="btn quantity-btn quantity-left">-</button>
                                        <button *ngIf="product.cart.quantity <5" (click)="increaseProduct(i)"
                                            class="btn quantity-btn quantity-right">+</button>
                                    </div>
                                </div>
                                <p>
                                    Color {{product.cart.color}}, talla
                                    {{product.cart.size}} <br>
                                    <span *ngIf="product.cart.quantity != 1" ngbTooltip="Valor por unidad">UV </span>
                                    <span *ngIf="product.cart.quantity != 1">
                                        <span *ngIf="product.specialPrice == '0'">{{product.price | currency : 'USD' : 'symbol' :
                                            '1.0-0' }}</span>
                                        <del class="special-price" *ngIf="product.specialPrice != '0'">{{product.price | currency :
                                            'USD' : 'symbol' : '1.0-0'}}</del>
                                        <span *ngIf="product.specialPrice != '0'"> {{product.specialPrice | currency : 'USD' :
                                            'symbol' : '1.0-0'}}</span>
                                        <br>
                                    </span>
                                    Total <span *ngIf="product.specialPrice == '0'">{{calculateTotal(product.price,
                                        product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0' }}</span>
                                    <del class="special-price" *ngIf="product.specialPrice != '0'">{{calculateTotal(product.price,
                                        product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</del>
                                    <span *ngIf="product.specialPrice != '0'"> {{calculateTotal(product.specialPrice,
                                        product.cart.quantity) | currency : 'USD' : 'symbol' : '1.0-0'}}</span>
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex flex-row align-items-center">
                            <a ngbTooltip="Eliminar producto" (click)="deleteItem(product)" role="button">
                                <bi name="trash" class="config-icon icon-small"></bi>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="col-lg-2 d-md-block d-lg-none">
        <hr>
        <table class="common-font">
            <tbody>
                <tr>
                    <td class="align-right">
                        Envío:
                    </td>
                    <td>
                        &nbsp;{{deliveryAmount | currency : 'USD' : 'symbol' : '1.0-0'}} <span class="about-circle"
                            ngbTooltip="Envío gratis a partir de compras superiores a {{freeDelivery | currency : 'USD' : 'symbol' : '1.0-0' }}">?</span>
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        Total productos:
                    </td>
                    <td>
                        &nbsp;{{taxReturnBase | currency : 'USD' : 'symbol' : '1.0-0'}}
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        Total Iva:
                    </td>
                    <td>
                        &nbsp;{{tax | currency : 'USD' : 'symbol' : '1.0-0'}}
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        <b style="font-size: 25px;">Total:</b>
                    </td>
                    <td>
                        &nbsp;<b style="font-size: 25px;">{{cartTotal | currency : 'USD' : 'symbol' : '1.0-0'}}</b>
                    </td>
                </tr>
            </tbody>
        </table>
        <hr>
    </div>
    <div class="col-lg-4" *ngIf="cartCount$ > 0">
        <ngb-accordion #acc="ngbAccordion" class="accordion" activeIds="personal-data" [closeOthers]="true"
            (panelChange)="beforeChange($event)">
            <ngb-panel id="personal-data" title="Datos personales">
                <ng-template ngbPanelContent>
                    <!-- Input validador de email -->
                    <div *ngIf="!registerUser">
                        <span class="form-label">
                            Correo *
                        </span>
                        <span *ngIf="email.touched && !email.valid" class="form-message-error">
                            Ingrese un correo válido
                        </span>
                        <div class="input-group mb-1">
                            <input [(ngModel)]="user.email" name="email" #email="ngModel" class="form-control"
                                type="email" placeholder="Ingrese el correo" required
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />
                            <div ngbTooltip="{{!email.valid ? 'Ingrese un email válido' : ''}}"
                                class="input-group-append">
                                <button [disabled]="!email.valid" class="btn
                                    btn-outline-dark" (click)="findEmail()">
                                    Validar email
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- form regitro nuevo usuario -->
                    <form #formPersonalData="ngForm" *ngIf="!showDeliveryData">
                        <div *ngIf="registerUser">
                            <div class="row">
                                <div class="col-lg-12">
                                    <span class="form-label">
                                        Correo *
                                    </span>
                                    <div class="input-group mb-1"
                                        ngbTooltip="Te quedo mal el correo? no pasa nada, selecciona cambiar de email para validar uno nuevo.">
                                        <input [(ngModel)]="user.email" name="email" #email="ngModel"
                                            class="form-control" type="email" placeholder="Ingrese el correo" required
                                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" disabled="disabled"
                                            [ngStyle]="{
                                                'color': '#454545',
                                                'background-color': '#afafaf'
                                                }" />
                                        <a class="btn btn-outline-dark input-group-append"
                                            (click)="registerUser = false">
                                            Cambiar de email
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-1">
                                        <span class="form-label">
                                            Nombre *
                                        </span>
                                        <span *ngIf="name.touched && !name.valid" class="form-message-error">
                                            Ingrese un nombre válido
                                        </span>
                                        <input [(ngModel)]="user.name" name="name" #name="ngModel" class="form-control"
                                            type="text" placeholder="Ingrese el nombre" required pattern="[a-zA-Z ]+" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-1">
                                        <span class="form-label">
                                            Apellido *
                                        </span>
                                        <span *ngIf="lastName.touched && !lastName.valid" class="form-message-error">
                                            Ingrese un apellido válido
                                        </span>
                                        <input [(ngModel)]="user.lastName" name="lastName" #lastName="ngModel"
                                            class="form-control" type="text" placeholder="Ingrese el apellido" required
                                            pattern="[a-zA-Z ]+" />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-1">
                                        <span class="form-label">
                                            Número de Cédula *
                                        </span>
                                        <span *ngIf="cc.touched && !cc.valid" class="form-message-error">
                                            Ingrese un número de cédula válido
                                        </span>
                                        <input [(ngModel)]="user.cc" name="cc" #cc="ngModel" class="form-control"
                                            type="number" placeholder="Ingrese el número de cédula"
                                            pattern="([0-9]){6,10}" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="mb-1">
                                        <span class="form-label">
                                            Número de celular *
                                        </span>
                                        <span *ngIf="phone.touched && !phone.valid" class="form-message-error">
                                            Ingrese un número de celular válido
                                        </span>
                                        <input [(ngModel)]="user.phone" name="phone" class="form-control" type="tel"
                                            #phone="ngModel" placeholder="Ingrese el número de celular" appPhoneMask
                                            pattern="(\d{3}) (\d{3}) (\d{4})|(\d{3})(\d{3})(\d{4})" required />
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <span ngbTooltip="{{ !formPersonalData.form.valid ?
                                'Ingrese los campos obligatorios' : ''}}">
                                <button class="btn btn-outline-dark" (click)="createUser(acc)"
                                    [disabled]="!formPersonalData.form.valid">
                                    Pasar a Datos de entrega
                                </button>
                            </span>
                        </div>
                    </form>
                    <!-- Vista con datos del usuario -->
                    <div *ngIf="showDeliveryData">
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Correo</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.email}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Nombre</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.name}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Apellido</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.lastName}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Número de Cédula</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.cc}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Número de Teléfono</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{user.phone}}</span><br>
                        <hr>
                        <button class="btn btn-outline-dark" (click)="editPersonalData()">
                            Editar datos personales
                        </button>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="delivery-data" title="Datos de entrega {{ !showDeliveryData ? '(Llena tus datos personales)' : ''}}" [disabled]="!showDeliveryData">
                <ng-template ngbPanelContent>
                    <!-- Form con datos de entrega -->
                    <form #forlgeliveryData="ngForm" *ngIf="!showPaymentMethod">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-1">
                                    <span class="form-label">
                                        Departamento
                                    </span>
                                    <select class="form-select" aria-label="Selecione el departamento" name="deparment"
                                        [(ngModel)]="department.id" (change)="changeDeparment()" pattern="^[1-9]\d*$"
                                        required>
                                        <option [ngValue]="'0'" selected disabled>Selecione el departamento</option>
                                        <option *ngFor="let department of departments" [ngValue]="department.id">
                                            {{department.department}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6" *ngIf="department.id != '0' && cities.length > 0">
                                <div class="mb-1">
                                    <span class="form-label">
                                        Ciudad
                                    </span>
                                    <select class="form-select" aria-label="Selecione la ciudad" name="city"
                                        [(ngModel)]="city.id" (change)="changeCity()" pattern="^[1-9]\d*$" required>
                                        <option [ngValue]="'0'" selected disabled>Selecione la ciudad</option>
                                        <option *ngFor="let city of cities" [ngValue]="city.id">{{city.city}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6"
                                *ngIf="city.id != '0' && fcity.hasLocalities && localities.length > 0">
                                <div class="mb-1">
                                    <span class="form-label">
                                        Localidad
                                    </span>
                                    <select class="form-select" aria-label="Selecione la localidad" name="locality"
                                        [(ngModel)]="locality.id" (change)="changeLocality()" pattern="^[1-9]\d*$"
                                        required>
                                        <option [ngValue]="'0'" selected disabled>Selecione la localidad</option>
                                        <option *ngFor="let locality of localities" [ngValue]="locality.id">
                                            {{locality.locality}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6"
                                *ngIf="city.id != '0' && !fcity.hasLocalities || city.id != '0' && fcity.hasLocalities && locality.id != '0'">
                                <div class="mb-1">
                                    <span class="form-label">
                                        Dirección
                                    </span>
                                    <span *ngIf="addr.touched && !addr.valid" class="form-message-error">
                                        Ingrese la dirección
                                    </span>
                                    <input [(ngModel)]="addres" name="addres" class="form-control" type="string"
                                        #addr="ngModel" placeholder="Ingrese la dirección"
                                        pattern="[a-z A-Z1-9#-_]{5,50}" required />
                                </div>
                            </div>
                        </div>
                        <hr>
                        <button class="btn btn-outline-dark" (click)="validateDeliveryData(acc)"
                            [disabled]="!forlgeliveryData.form.valid">
                            Pasar a Método de pago
                        </button>
                    </form>
                    <!-- Vista con datos de entrega -->
                    <div *ngIf="showPaymentMethod">
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Departamento</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{fdepartment.department}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Ciudad</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{fcity.city}}</span><br>
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;" *ngIf="locality.id != '0'">Localidad</span>
                        <span style="font-size: 16px; margin-top: 0px;"
                            *ngIf="locality.id != '0'">{{flocality.locality}}</span><br *ngIf="locality.id != '0'">
                        <span style="font-weight: bold; display: block;
                            margin-bottom: -12px;">Direccion</span>
                        <span style="font-size: 16px; margin-top: 0px;">{{addres}}</span><br>
                        <hr>
                        <button class="btn btn-outline-dark" (click)="editDeliveryData()">
                            Editar datos de entrega
                        </button>
                    </div>
                </ng-template>
            </ngb-panel>
            <ngb-panel id="paymenth-method" title="Método de pago {{ !showPaymentMethod ? '(Llena tus datos de pago)' : ''}}" [disabled]="!showPaymentMethod">
                <ng-template ngbPanelContent>
                    <form #formPaymentMethod="ngForm">
                        <div style="display: block;">
                            <!-- TODO se desactiva metodo de pago de payu -->
                            <!-- <div class="form-check mb-4">
                                <input class="form-check-input" type="radio" name="payOptions" id="inlinePayOptionPayU"
                                    value="PayU" [(ngModel)]="paymentMethod" required>
                                <label class="form-check-label" for="inlinePayOptionPayU">
                                    <img src="/assets/img/logo_payu.png" style="width: 36px; margin-right: 5px;"
                                        alt="payu" title="payu">
                                    <span style="margin-top: 3px;"
                                        ngbTooltip="Paga con tu tarjeta preferida, PSE o Efectivo.">PayU</span>
                                </label>
                            </div> -->
                            <div class="form-check mb-4">
                                <input class="form-check-input" type="radio" name="payOptions"
                                    id="inlinePayOptionBancolombia" value="Bancolombia" [(ngModel)]="paymentMethod"
                                    required>
                                <label class="form-check-label" for="inlinePayOptionBancolombia" style="display: flex;">
                                    <img src="/assets/img/bancolombia.png" style="width: 36px; margin-right: 5px;"
                                        alt="wompi" title="wompi">
                                    <span style="margin-top: 3px;"
                                        ngbTooltip="Te llegara un Correo con el link de pago.">Bancolombia</span>
                                </label>
                            </div>
                            <div class="form-check mb-4">
                                <input class="form-check-input" type="radio" name="payOptions"
                                    id="inlinePayOptionEfectivo" value="Efectivo" [(ngModel)]="paymentMethod" required>
                                <label class="form-check-label" for="inlinePayOptionEfectivo" style="display: flex;">
                                    <bi name="cash-coin" class="icon-small" style="color: green; margin-right: 5px;">
                                    </bi>
                                    <span style="margin-top: 3px;"
                                        ngbTooltip="Pague cuando reciba el producto">Efectivo</span>
                                </label>
                            </div>
                        </div>
                        <div class="mb-1" style="
                        font-size: 12px; 
                        border: 1px solid #fff;
                        border-radius: 10px;
                        padding: 6px;">
                            Una vez se confirme el pago de su producto (si aplica) la entrega se realizará entre 3 a 5
                            días hábiles.
                        </div>
                        <div class="mb-1" style="font-size: 12px;">
                            <input class="form-check-input" [(ngModel)]="user.aceptTermAndConditions"
                                name="aceptProcessingPersonalData" type="checkbox" id="flexCheckDefault" required>
                            <label class="form-check-label" for="flexCheckDefault" style="cursor: grab;"> Autorizo
                            </label>
                            el uso y tratamiento de mis datos personales de acuerdo a la política que se
                            encuentra
                            <a [ngStyle]="{
                                            'color': '#fff',
                                            'font-weight': 'bold',
                                            'text-decoration': 'none'
                                            }" [routerLink]="['/processing-personal-data']"
                                ngbTooltip="Tratamiento de datos personales" target="_blank"
                                rel="processing-personal-data">
                                aquí
                            </a>
                            y acepto los términos y condiciones descritos
                            <a [ngStyle]="{
                                'color': '#fff',
                                'font-weight': 'bold',
                                'text-decoration': 'none'
                                }" [routerLink]="['/terms-and-conditions']" ngbTooltip="Términos y condiciones"
                                target="_blank" rel="terms-and-conditions">
                                aquí.
                            </a>
                        </div>
                    </form>
                    <!-- target="payment_popup"
                    onsubmit="window.open('about:blank','payment_popup','width=900,height=500');" -->
                    <form ngNoForm [formGroup]="payUForm" name="payuform" method="post" action="{{checkoutPayuUrl}}"
                        #payuform>
                        <input name="merchantId" formControlName="merchantId" type="hidden">
                        <input name="accountId" formControlName="accountId" type="hidden">
                        <input name="description" formControlName="description" type="hidden">
                        <input name="referenceCode" formControlName="referenceCode" type="hidden" [(ngModel)]="orderID">
                        <input name="amount" formControlName="amount" type="hidden" [(ngModel)]="cartTotal">
                        <input name="tax" formControlName="tax" type="hidden" [(ngModel)]="tax">
                        <input name="taxReturnBase" formControlName="taxReturnBase" type="hidden"
                            [(ngModel)]="taxReturnBase">
                        <input name="additionalValue" formControlName="additionalValue" type="hidden"
                            [(ngModel)]="additionalValue">
                        <input name="currency" formControlName="currency" type="hidden">
                        <input name="signature" formControlName="signature" type="hidden" [(ngModel)]="signature">
                        <input name="test" formControlName="test" type="hidden">
                        <input name="buyerEmail" formControlName="buyerEmail" type="hidden" [(ngModel)]="user.email">
                        <input name="responseUrl" formControlName="responseUrl" type="hidden">
                        <input name="confirmationUrl" formControlName="confirmationUrl" type="hidden">
                        <input name="sourceUrl" formControlName="sourceUrl" type="hidden">
                        <input name="shippingAddress" formControlName="shippingAddress" type="hidden"
                            [(ngModel)]="addres">
                        <input name="shippingCity" formControlName="shippingCity" type="hidden"
                            [(ngModel)]="fcity.city">
                        <input name="shippingCountry" formControlName="shippingCountry" type="hidden">
                        <input name="buyerFullName" formControlName="buyerFullName" type="hidden"
                            [(ngModel)]="fullName">
                    </form>
                    <hr>
                    <span
                        ngbTooltip="{{ !formPaymentMethod.form.valid ? 'Seleccione el Método de pago y acepte los términos y condiciones.' : ''}}"
                        ngbTooltip="">
                        <button class="btn btn-outline-dark" (click)="checkout()"
                            [disabled]="!formPaymentMethod.form.valid || paymentMethod == ''">
                            Finalizar pedido
                        </button>
                    </span>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div class="col-lg-3 d-none d-lg-block">
        <table class="common-font">
            <tbody>
                <tr>
                    <td class="align-right">
                        Envío:
                    </td>
                    <td>
                        &nbsp;{{deliveryAmount | currency : 'USD' : 'symbol' : '1.0-0'}} <span class="about-circle"
                            ngbTooltip="Envío gratis a partir de compras superiores a {{freeDelivery | currency : 'USD' : 'symbol' : '1.0-0' }}">?</span>
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        Total productos:
                    </td>
                    <td>
                        &nbsp;{{taxReturnBase | currency : 'USD' : 'symbol' : '1.0-0'}}
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        Total Iva:
                    </td>
                    <td>
                        &nbsp;{{tax | currency : 'USD' : 'symbol' : '1.0-0'}}
                    </td>
                </tr>
                <tr>
                    <td class="align-right">
                        <b style="font-size: 25px;">Total:</b>
                    </td>
                    <td>
                        &nbsp;<b style="font-size: 25px;">{{cartTotal | currency : 'USD' : 'symbol' : '1.0-0'}}</b>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>