import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { GeneralError } from 'src/app/models/errors';
import { City, Deparment, Locality } from 'src/app/models/location';
import { OrderResponse } from 'src/app/models/order';
import { Product } from 'src/app/models/product';
import { User } from 'src/app/models/user';
import { CartService } from 'src/app/services/cart.service';
import { LocationService } from 'src/app/services/location.service';
import { OrderService } from 'src/app/services/order.service';
import { SeoService } from 'src/app/services/seo.service';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {
    cartProducts$ = this._cartService.cartProducts$
    cartTotal: string = "0"
    cartCount$: number = 0
    haveProductsMessage = 'productos'
    showDeliveryData = false
    showPaymentMethod = false
    registerUser = false
    user = {} as User
    departments = {} as Deparment[]
    cities = {} as City[]
    localities = {} as Locality[]
    department = new Deparment(
        "0",
        ""
    )
    city = new City(
        "0",
        "",
        false,
        false,
        ""
    )
    locality = new Locality(
        "0",
        "",
        false,
        false,
        ""
    )
    fdepartment = {} as Deparment
    fcity = {} as City
    flocality = {} as Locality
    addres = ""
    paymentMethod = ""
    signature = ""
    orderID = ""
    tax = 0
    taxReturnBase = 0
    additionalValue = 0
    deliveryAmount = 0
    fullName = ""
    freeDelivery = environment.freeDelivery
    checkoutPayuUrl = environment.checkoutPayuUrl
    movil = true

    resizeObservable$!: Observable<Event>
    resizeSubscription$!: Subscription
    private cartCountSubscription!: Subscription

    payUForm = this.formBuilder.group({
        merchantId: environment.merchantID,
        accountId: environment.accountID,
        description: 'Cantsu SAS',
        referenceCode: this.orderID,
        amount: this.cartTotal,
        tax: this.tax,
        taxReturnBase: this.taxReturnBase,
        additionalValue: this.additionalValue,
        currency: 'COP',
        signature: this.signature,
        test: false,
        responseUrl: environment.webUrl + "order-detail",
        confirmationUrl: environment.prductApiUrl + "payu-confirmation/v1",
        sourceUrl: environment.webUrl + "order-detail",
        buyerEmail: this.user.email,
        shippingAddress: this.addres,
        shippingCity: this.fcity.city,
        shippingCountry: 'CO',
        buyerFullName: this.fullName,
    });

    constructor(
        private _seoService: SeoService,
        private formBuilder: FormBuilder,
        private _cartService: CartService,
        private _usersService: UsersService,
        private _locationService: LocationService,
        private _orderService: OrderService,
        private _router: Router,
    ) {
        window.scrollTo(0, 0)
        this.cartCountSubscription = this._cartService.cartCount$.subscribe((count) => {
            this.cartCount$ = count
            if (count == 1) {
                this.haveProductsMessage = 'producto'
            } else {
                this.haveProductsMessage = 'productos'
            }
        })
        this._cartService.cartTotal$.subscribe((total) => this.cartTotal = total.toString())
        this._cartService.cartIVA$.subscribe((iva) => this.tax = iva)
        this._cartService.cartSubTotal$.subscribe((subTotal) => this.taxReturnBase = subTotal)
        this._cartService.cartDelivery$.subscribe((delivery) => this.deliveryAmount = delivery)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
        this._locationService.getDepartments().subscribe({
            next: (v) => {
                this.departments = v
            },
            error: (e) => console.error(e),
        })
        this._seoService.setTitle('Cantsu - Finalizar pedido')
        this._seoService.setDescription('Tu Carrito, finalizacion del pedido')
        if (window.innerWidth <= 990) { 
            this.movil = true
        } else {
            this.movil = false
        }
        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            if (window.innerWidth <= 990) { 
                this.movil = true
            } else {
                this.movil = false
            }
        })
    }

    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    } 

    ngOnDestroy(): void {
        this.cartCountSubscription.unsubscribe()
        this.resizeSubscription$.unsubscribe()
    }

    deleteItem(product: Product) {
        this._cartService.deleteProduct(product)
    }

    calculateTotal(value: string, quantity: number): string {
        return String(Number(value) * quantity)
    }

    increaseProduct(i: number): void {
        this._cartService.increaseProduct(i)
    }

    decreaseProduct(i: number): void {
        this._cartService.decreaseProduct(i)
    }

    beforeChange($event: NgbPanelChangeEvent) {
        if ($event.panelId === 'preventchange-2') {
            $event.preventDefault();
        }

        if ($event.panelId === 'preventchange-3' && $event.nextState === false) {
            $event.preventDefault();
        }
    }

    findEmail(): void {
        this.registerUser = true
        // this._usersService.existUser(this.user.email).subscribe({
        //     next: (v) => {
        //         switch (v.status) {
        //             case 200:
        //                 this.registerUser = true
        //                 break;
        //             case 280:
        //                 this.registerUser = true
        //                 break;
        //         }
        //     },
        //     error: (e) => console.error(e),
        // })
    }

    createUser(acc: NgbAccordion): void {
        this.showDeliveryData = true
        setTimeout(() => {
            acc.toggle('delivery-data')
        }, 100)
    }

    editPersonalData(): void {
        this.showDeliveryData = false
        this.showPaymentMethod = false
    }

    editDeliveryData(): void {
        this.showPaymentMethod = false
    }

    validateDeliveryData(acc: NgbAccordion): void {
        this.showPaymentMethod = true
        setTimeout(() => {
            acc.toggle('paymenth-method')
        }, 100)
    }

    changeDeparment(): void {
        let td = this.departments.find(
            d => d.id === this.department.id
        )
        if (td != undefined) {
            this.fdepartment = td
        }
        this.city.id = '0'
        this.locality.id = '0'
        this._locationService.getCities(this.department.id).subscribe({
            next: (v) => {
                this.cities = v
            },
            error: (e) => console.error(e),
        })
    }

    changeCity(): void {
        let tc = this.cities.find(
            c => c.id === this.city.id
        )
        if (tc != undefined) {
            this.fcity = tc
        }
        this.locality.id = '0'
        if (this.fcity.hasLocalities) {
            this._locationService.getLocalities(this.city.id).subscribe({
                next: (v) => {
                    this.localities = v
                },
                error: (e) => console.error(e),
            })
        }
    }

    changeLocality(): void {
        let tl = this.localities.find(
            l => l.id === this.locality.id
        )
        if (tl != undefined) {
            this.flocality = tl
        }
    }

    checkout(): void {
        this.fullName = this.user.name + " " + this.user.lastName
        this._orderService.checkout(this.user, this.fdepartment, this.fcity, this.flocality, this.addres, this.paymentMethod).subscribe({
            next: (v) => {
                if (v.status == 200) {
                    let tmpRes = <OrderResponse>v
                    this.signature = tmpRes.signature
                    this.orderID = tmpRes.orderID
                    alert("Se creo el pedido de forma correcta.")

                    switch (this.paymentMethod) {
                        case "PayU":
                            setTimeout(() => {
                                this.submitPayUForm()
                                this._cartService.clearProducts()
                            }, 100)
                            break
                        default:
                            setTimeout(() => {
                                this._cartService.clearProducts()
                                this._router.navigate(['/order-detail', this.orderID])
                            }, 300)
                            break
                    }
                    return
                }
                let tmpErr = <GeneralError>v
                console.error(tmpErr)
                alert("Ocurrio un error creando la orden, " + tmpErr.detail)
            },
            error: (e) => console.error(e),
        })
    }

    @ViewChild('payuform') formElement: any
    submitPayUForm(): void {
        this.formElement.nativeElement.submit()
    }
}
