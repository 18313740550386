<h3>TÉRMINOS Y CONDICIONES</h3>
<p>Recomendamos leer con especial cuidado el presente documento en el cual se establecen las condiciones para el uso del
    sitio web y compra de productos de la siguiente manera:</p>
<p>Al ingresar en nuestra página web usted acepta plenamente los términos y condiciones para el uso de la misma y tiene
    en cuenta que adquirir cualquiera de nuestros productos por este medio usted acepta plenamente los términos y
    condiciones para la compra de productos.</p>
<p>A continuación, se establecen los términos y condiciones.</p>
<h3>USO DE LA PÁGINA WEB</h3>
<p>
    La información que leerá a continuación esta basada bajo la normatividad vigente aplicada de Colombia, por esta
    razón CANTSU S.A.S. No se hace responsable de que lo aquí descrito sea correspondiente y/o de acuerdo a lo indicado
    en cualquier país diferente al mencionado anteriormente.
</p>
<p>
    Los términos y condiciones en el presente documento podrán ser modificados según las indicaciones de la normatividad
    colombiana sin previo aviso. Por esta razón los mismo serán vigentes solo en el momento de la consulta y compra.
</p>
<p>
    En momento que se ingrese a nuestra pagina web WWW.CANTSU.COM aceptara los términos y condiciones descritos.
</p>
<h3>
    CREACIÓN DEL USUARIO
</h3>
<p>
    Al momento de realizar su gestión con nuestra página web serán solicitados datos personales, los cuales serán
    guardados automáticamente y de esta forma el cliente como CANTSU tendrán conocimientos del estado de sus compras y
    funciones habilitadas en la página web.
</p>
<p style="color: red;">
    Los datos suministrados serán
</p>
<p>
    Si en algún momento llega a tener inconvenientes con nuestra pagina por favor dirigir un correo electrónico a
    nuestro e-mail: cervicioalcliente@cantsu.com.
</p>
<h3>
    CARACTERÍSTICAS DE LOS PRODUCTOS
</h3>
<p>
    CANTSU S.A.S. Elabora cada una de las prendas pensado en la comodidad y gusto de nuestros clientes: colores, estilos
    y tallas están a su plena disposición, sin embargo los colores pueden variar de acuerdo al equipo en el que se vean
    los productos ya que a pesar de ser fotos totalmente reales las colores pueden variar por las características de
    visualización de dichos equipos.
</p>
<h3>
    INDUSTRIAL Y DERECHOS DE AUTOR
</h3>
<p>
    Los logos, marquillas, nombres, diseños, gráficos, fotografías e imágenes, videos, publicidad, contenido de la
    pagina web, textos, base de datos y cualquier otro símbolo o signo distintivo puestos a disposición el cualquiera de
    nuestras redes y pagina web son propiedad exclusiva de CANTSU S.A.S.
</p>
<p>
    Al navegar por cualquiera de nuestra redes otorga el derecho a nuestros clientes para copiar el contenido de CANTSU
    S.A.S. para uso personal mas no da el derechos de utilizar el contenido para uso comercial, por esta razón no debe
    modificarse de ninguna forma el mismo y conservar los derechos de autor descritos mediante leyendas, logos o
    símbolos de propiedad CANTSU S.A.S. La autorización aquí conferida en ningún caso podrá ser entendida como concesión
    u otorgamiento a cualquier título de autorizaciones, licencias o cualquier otro derecho para usar o disponer de
    cualquier forma de la propiedad industrial, sin el permiso por escrito de CANTSU o del titular de los derechos de la
    misma.
</p>
<p>
    Los contenidos de nuestras páginas web están bajo la protección de las normas de propiedad industrial, las normas
    nacionales e internacionales que aplique como también los derechos de autor.
</p>
<p>
    Esta totalmente prohibido el uso de cualquiera de nuestros contenidos para copias, modificaciones trabajos derivados
    sin previa autorización por parte de CANTSU S.A.S, ventas externas, fotocopias y usos de cualquier otra índole.
    Cualquier uso no autorizado constituirá una violación a los presentes términos y condiciones y a las normas vigentes
    nacionales e internacionales sobre propiedad industrial y/o derechos de autor según corresponda.
</p>
<h3>
    RESPONSABILIDAD
</h3>
<p>
    CANTSU se enfoca en que las paginas de redes sociales y la pagina web sean totalmente claras para el uso de
    cualquier cliente, sin embargo, no se hace responsables de virus existentes, de mal uso de las mismas, de problemas
    con el servidor, mala conexión a internet, problemas técnicos, interferencias en la comunicación o cualquier otro
    daño, perjurio o reclamos derivado del uso de nuestras redes. Dicho esto, si el mal uso o uso indebido del material
    de cualquiera de nuestras redes y nuestra página web deriva al reparar, arreglar equipos o información, el cliente
    asume la totalidad de los costos que implique dicho arreglo.
</p>
<p>
    CANTSU solo se hace responsable en solucionar inconvenientes respecto a productos adquiridos o productos que se
    encuentran en proceso de despacho de nuestra página.
</p>
<h3>
    COMPRA DE PRODUCTOS
</h3>
<p>
    Al momento de la compra, la comercializadora CANTSU S.A.S. solicitará datos pata registro y control de adquisición
    del producto el cual debe ser una persona debidamente facultada o persona natural; En caso de ser una persona
    jurídica CANTSU S.A.S. asumirá de buena fe que esta haciendo contrato con el representante legal total mente
    facultado para hacer el proceso de adquisición del producto. Dicho esto, CANTSU S.A.S sociedad comercial debidamente
    constituida bajo las leyes colombianas, identificada con NIT: 901638812-1 propietaria del sitio web www.cantsu.com
    no se hace responsable de suplantaciones personales de cualquier cliente.
</p>
<p>
    La compra y adquisición de nuestros productos deberán ser efectuadas por personal totalmente facultadas y mayores de
    catorce (14) años que están acompañados por un mayor de edad al momento de recibir y firmar el comprobante de
    entrega con su respectivo recibo.
</p>
<p>
    NOTA: Al realizar cualquier compra en nuestra página web deberá suministrar su información personal y de contacto,
    dirección de envío y pago y demás información requerida para procesar el pago y envío de su compra.
</p>
<h3>
    ACEPTACIÓN DE LOS TÉRMINOS
</h3>
<p>
    Para finalizar la compra en nuestra página web WWW.CANTSU.COM el cliente deberá haber leído y entendido los términos
    y condiciones como también cumplir con todas las leyes y reglamentos aplicables que hagan parte de la legislación
    colombiana. Lo cual asumiremos con la aceptación debidamente señalada en el momento de la compra.
</p>
<p>
    La compra y/o operaciones entre CANTSU y el cliente estarán aplicados a los términos vigentes en el momento de
    ejecución de la misma, los cuales están sujetos a cambios sin previo aviso en cualquier momento.
</p>
<h3>
    COMPRA DEL PRODUCTO
</h3>
<p>
    Para adquirir nuestros productos el cliente deberá ingresar a nuestra página web WWW.CANTSU.COM, seleccionar el
    producto de su agrado, seleccionar talla y color, aceptar términos y condiciones, política de tratamiento de datos y
    a su vez suministrar los datos allí especificados para proceder al pago y su vez realizar el proceso de entrega del
    producto.
</p>
<p>
    Al dar clic en botón (Comprar Ahora) se mostrará el consolidados de la información de la compra y por tanto se
    asumirá la aceptación de dicho contrato.
</p>
<p>
    La constancia de compra se vera reflejada en el correo electrónico. La cadena de notificaciones transaccionales al
    cliente, será completada posteriormente con correos electrónicos informando:
</p>
<p>
    • Aprobación del pago <br>
    • La facturación de su pedido<br>
    • Confirmación de envío del pedido, o cualquier otro medio que disponga CANTSU.<br>
</p>
<h3>
    PRECIO
</h3>
<p>
    Los costos de los productos, impuestos y gastos de envió se verán reflejados en WWW.CANTSU.COM, teniendo en cuenta
    que dichos precios se estarán actualización teniendo en cuenta precios justo y comportamiento económico. Sin
    embargo, si llega a haber alguna fluctuación o inconsistencia el cliente podrá comunicarse con nosotros en
    servicioalcliente@cantsu.com para resolver cualquier inconveniente e inquietud.
</p>
<p>
    CANTSU S.A.S. informara costos de transporte, envió y producto en el transcurso de diligenciamiento de compra como
    también en la constancia de compra final.
</p>
<h3>
    ENVIOS
</h3>
<p>
    Los costos de envío estarán sujetos a la zona de cobertura(Ver excepciones a nuestra cobertura de envíos) y costros
    de la transportadora los cuales son totalmente ajenos a CANTSU S.A.S. Por esta razón CANTSU S.A.S. no se hace
    responsable de la manipulación durante el proceso de transporte del producto sin embargo nos aseguramos de llevar el
    producto totalmente protegido.
</p>
<p>
    Los precios publicados para los productos incluidos en nuestro sitio web, aplican exclusivamente para las compras
    que sean efectuadas a través de WWW.CANTSU.COM.
</p>
<p>
    El plazo máximo para la entrega de los productos será de diez (10) días hábiles, contados a partir de la aprobación
    del pago, siempre y cuando no medie ningún caso fortuito o de fuerza mayor, entre los cuales se encuentra a manera
    de ejemplo: hechos del hombre o de la naturaleza, huelgas, paros, revoluciones, rebelión sedición, asonada,
    atentados, cortes de fluidos, derrumbes, terremotos, avalanchas, desbordamientos, caídas de puentes, entre otros.
</p>
<p>
    PARÁGRAFO: La empresa transportadora realizará máximo tres (3) intentos de entrega de los productos adquiridos en la
    dirección de envío registrada por el cliente al momento de la compra. En caso que los intentos de entrega resulten
    infructuosos, retornarán los bienes a las instalaciones de CANTSU. Se procederá a contactar al cliente para
    programar una nueva entrega.
</p>
<p>
    Si no se logra acordar la entrega del producto, este será almacenado por un término máximo de seis (6) meses, tiempo
    durante el cual el cliente deberá comunicarse con CANTSU, a través de los canales establecidos en el presente
    documento atencionalcliente@cantsu.com .
</p>
<p>
    Una vez cumplido el término máximo de almacenamiento antes relacionado, CANTSU no se hará responsable por el
    producto.
</p>
<p>
    De igual forma, al aceptar los presentes términos y condiciones el cliente acepta que la entrega de productos se
    efectuará a través de una empresa transportadora independiente, que se encuentre legalmente habilitada y se somete
    al régimen de transporte de mercancías vigente en la legislación colombiana.
</p>
<h3>
    PAGOS
</h3>
<p>
    Los pagos se efectuaran solamente por los siguientes medios <br>
    • Daviplata<br>
    • Nequi<br>
    • Baloto<br>
    • Payu<br>
    • Bancolombia<br>
    • Efectty<br>
</p>
<p>
    Antes de efectuar el pago del producto, el cliente deberá aceptar los términos y condiciones según los medios de
    pago dispuestos como también los indicados en WWW.CANTSU.COM .
</p>
<p>
    CANTSU pondrá a disposición del cliente un sistema de pago y conexión segura para el proceso de compra, pero no será
    responsable de interferencias de comunicación ni fallos de las entidades financieras, como tampoco de fallos por
    omisión o mala ejecución en el proceso de pago.
</p>
<h3>
    DERECHO DE RETRACTO
</h3>
<p>
    En el art. 47 del Estatuto del Consumidor (Ley 1480 de 2011) el cliente podrá devolver los bienes comprados dentro
    de los cinco (5) días hábiles siguientes a la fecha de entrega de los bienes.
</p>
<p>
    Para poder ejercer ese derecho el cliente deberá devolver el producto a CANTSU por los mismos medios y bajos las
    mismas condiciones con el que fue entregado, asumiendo el costo total de envío y transporte.
</p>
<p>
    De acuerdo con lo establecido en el citado Estatuto del Consumidor, se exceptúan del Derecho de Retracto, y según el
    objeto social que desempeña CANTSU, los siguientes casos
</p>
<p>
    En los contratos de suministro de bienes confeccionados conforme a las especificaciones del consumidor o claramente
    personalizados.
</p>
<p>
    Para gestionar la devolución de su producto se debe comunicar mediante una de las siguientes opciones:
</p>
<p>
    Línea de servicio al cliente de CANTSU al 3112305775 (horario de lunes a viernes de 9am-6pm).
</p>
<p>
    Correo: atencionalcliente@cantsu.com
</p>
<p>
    Página web: WWW.CANTSU.COM en el formulario de 'Contáctenos'.
</p>
<p>
    La devolución de dinero será efectuada en los términos que se describen dentro del presente documento.
</p>
<h3>
    REVERSIÓN DEL PAGO
</h3>
<p>
    De acuerdo a la ley el comprador de un producto mediante vías virtuales, es decir pagos con tarjeta de crédito,
    débito, entre otros medios de pagos electrónicos podrá reversar la compra en caso de fraude, operaciones ilícitas,
    el producto no corresponda, el producto no sea recibido o sea defectuoso.
</p>
<p>
    Dicho proceso tiene un tiempo máximo de cinco (5) días hábiles después de conocimiento de la novedad para presentar
    la solicitud ante CANTSU S.A.S. Mediante los siguientes medios:
</p>
<p>
    Línea de servicio al cliente de CANTSU al 3112305775 (horario de lunes a viernes de 9am-6pm).
</p>
<p>
    Correo: atencionalcliente@cantsu.com
</p>
<p>
    Página web: WWW.CANTSU.COM en el formulario de 'Contáctenos'.
</p>
<p>
    En dicha solicitud anexar recibo expedido en momento de la compra, fotocopia de la cedula ampliada al 150% y
    certificado bancario.
</p>
<p>
    Una vez verificada la compra se ara el respectivo estudio del caso y se dará respuesta inmediata mediante una
    notificación al cliente mediante correo electrónico y/o teléfono celular, se solicitará la devolución del producto
    defectuoso y se realizará su respectivo pago o reembolso del dinero.
</p>
<h3>
    REEMBOLSO DEL DINERO
</h3>
<p>
    Al rembolsar el dinero el cliente acepta desistir del proceso de compra y a su vez exime a CANTSU S.A.S. de
    cualquier responsabilidad. De la misma manera acepta tener claridad de la información y a su vez se dará comprobante
    del reembolso. El cliente con su solicitud de reembolso acepta la política de protección de datos personales de
    CANTSU y con ello lo expresado en la ley 1581 del 2012.
</p>
<h3>
    DOCUMENTOS DE FACTURACIÓN
</h3>
<p>
    La factura se entrega con el producto y a su vez al correo electrónico que registro en la plataforma de compra
    WWW.CANTSU.COM.
</p>
<h3>
    GRANTIA DEL PRODUCTO
</h3>
<p>
    La garantía cubrirá una totalidad de treinta (30) días calendario desde el día de la entrega del producto al cliente
    solo si el producto presenta las siguientes características. <br>
    • Defectos de costura<br>
    • Defectos de terminado<br>
    • Inconsistencia del producto con la imagen.<br>
</p>
<p>
    Dicha garantía no cobre deterioro normal del producto, uso inadecuado, ni daños efectuados por el mal cuidado y
    mantenimiento de las prendas. Cada una de las condiciones de cuidado se encuentran en las etiquetas de las prendas.
</p>
<p>
    Para efectos de garantía comunicarse a nuestras líneas de atención:
</p>
<p>
    Línea de servicio al cliente de CANTSU al 3112305775 (horario de lunes a viernes de 9am-6pm).
</p>
<p>
    Correo: atencionalcliente@cantsu.com
</p>
<p>
    Página web: WWW.CANTSU.COM en el formulario de 'Contáctenos'.
</p>
<p>
    Informando el nombre completo y número de cédula de comprador, número de la orden del pedido, número de documento
    equivalente a factura y la demás información que considere pertinente para la atención de la garantía.
</p>
<p>
    El área de servicio al cliente de CANTSU revisará la solicitud, y de ser procedente, solicitará la devolución del
    producto, para revisión del mismo. La recolección del producto se coordinará con CANTSU o con la empresa
    transportadora que efectuó la entrega, según le sea indicado al cliente.
</p>
<p>
    Con base en el análisis que sea efectuado, se generará una respuesta formal al cliente, por la cual le será
    informado si la reclamación por garantía resulta procedente o no.
</p>
<p>
    El producto deberá ser enviado en las mismas condiciones en que fue adquirido una vez aprobada la garantía. D e no
    ser probada la garantía se dará respuesta con claridad del por qué no será efectiva la misma.
</p>
<p>
    Para cambios se podrán efectuar por el mismos valor o superior en las siguientes condiciones:<br>
    • El plazo máximo para solicitar un cambio será de sesenta (30) días calendario contados desde la fecha la entrega
    de los productos<br>
    • El producto no debe presentar uso<br>
    • no debe haber sido modificado <br>
    • debe estar en perfectas condiciones<br>
    • El producto debe contener las etiquetas, accesorios y empaques originales. <br>
</p>
<p>
    Los cambios serán efectuado una vez comunicado con nuestras líneas de atención
</p>
<p>
    Línea de servicio al cliente de CANTSU al 3112305775 (horario de lunes a viernes de 9am-6pm).
</p>
<p>
    Correo: atencionalcliente@cantsu.com
</p>
<p>
    Página web: WWW.CANTSU.COM en el formulario de 'Contáctenos'.
</p>
<p>
    Dando respuesta en plazo máximo de quince (15) días hábiles. Se solicitarán datos de compra (factura, cedula y
    comprobante de pago). Y deberá diligenciar el formulario correspondiente. (En ese momento se indicarán al cliente
    los costos asociados al transporte del producto, informado quién deberá asumirlos).
</p>
<h3>
    ORIGEN DE FONDOS
</h3>
<p>
    El cliente acepta que el dinero con el cual se desarrollara el proceso de compra es totalmente legal y licito de lo
    contrario, es decir, que no provienen de ninguna actividad ilícita de las contempladas en el Código Penal colombiano
    o en cualquier otra norma concordante o que la modifique o adicione, por lo cual se exonera y releva a CANTSU S.A.S
    de cualquier tipo de responsabilidad penal, civil, tributaria y/o financiera en este sentido.
</p>
<p>
    Igualmente garantiza que directamente, ni sus socios, accionistas, ni vinculados o beneficiarios reales, tratándose
    de una persona jurídica:<br>
    • Han estado, o se encuentran incluidos en alguna de las listas que administra la Oficina de Control de Activos del
    Departamento del Tesoro de los Estados Unidos de América u otra similar.<br>
    • No tienen investigaciones en curso<br>
    • No han sido sindicados o condenados por narcotráfico ni lavado de activos o financiación del terrorismo<br>
    • Sus bienes, negocios y los recursos, al igual que los de sus accionistas, provienen de actividades lícitas.<br>
</p>
<h3>
    POLITICAS DE PRIVACIDAD Y TRATAMIENTO DE DATOS
</h3>
<p>
    La Política de Uso de Datos Personales y Administración de Bases de Datos de CANTSU S.A.S puede ser consultada en
    WWW.CANTSU.COM.
</p>
<h3>
    PETICIONES, QUEJAS Y RECLAMOS
</h3>
<p>
    Para radicar una Petición, Queja, Reclamo o Sugerencia (PQRS) por favor dirigirse al siguiente link
    atencionalcliente@cantsu.com en el que se podrán radicar nuevas solicitudes tales como quejas, reclamos,
    sugerencias, solicitudes de cambios, devoluciones, peticiones de información, entre otras.
</p>
<p>
    También puedes contactarnos a través de los siguientes canales, en los que podrás hacer seguimiento de tu solicitud:
</p>
<p>
    Línea de servicio al cliente nacional: 3112305775
</p>
<p>
    Dirección principal: calle 2 sur #72b-56 en Bogotá D.C.;Colombia
</p>
<h3>
    JORNADAS DE DÍAS SIN IVA – (Ley 2155 de 14 de septiembre de 2021)
</h3>
<p>
    Los Términos y Condiciones aquí incluidos aplican de manera íntegra a las compras realizadas durante las jornadas de
    exención del IVA “DÍAS SIN IVA”, dispuestas como un incentivo por el Gobierno Nacional conforme a la Ley 2155 de 14
    de septiembre de 2021, excepto en lo determinado respecto de los días estipulados para la entrega de los productos,
    los cuales, según la normatividad aplicable, deberán ser entregados al consumidor final a más tardar dentro de las
    dos (2) semanas siguientes a la emisión de la factura de compra o la expedición de documento equivalente.
</p>