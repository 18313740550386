import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Product } from 'src/app/models/product';
import { CartService } from 'src/app/services/cart.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit, OnDestroy {
    cartProducts$ = this._cartService.cartProducts$
    cartTotal$ = this._cartService.cartTotal$
    cartCount$: number = 0
    haveProductsMessage = 'productos'
    innerHeight: string = ''
    cartDelivery = this._cartService.cartDelivery$
    freeDelivery = environment.freeDelivery
    private wasInside = true
    private showCart$ = false

    private cartCountSubscription!: Subscription
    resizeObservable$!: Observable<Event>
    resizeSubscription$!: Subscription

    constructor(
        private _cartService: CartService,
        private _router: Router
    ) {
        this.cartCountSubscription = this._cartService.cartCount$.subscribe((count) => {
            this.cartCount$ = count
            if (count == 1) {
                this.haveProductsMessage = 'producto'
            } else {
                this.haveProductsMessage = 'productos'
            }
        })
        this._cartService.showCart$.subscribe((v) => {
            this.showCart$ = v
        })
    }

    ngOnInit(): void {
        this._cartService.getCTFromLocalStorage()
        this.innerHeight = (window.innerHeight - 310) + "px"

        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
            this.innerHeight = (window.innerHeight - 310) + "px"
        })
    }

    ngOnDestroy(): void {
        this.cartCountSubscription.unsubscribe()
        this.resizeSubscription$.unsubscribe()
    }

    deleteItem(product: Product) {
        this._cartService.deleteProduct(product)
    }

    calculateTotal(value: string, quantity: number): string {
        return String(Number(value) * quantity)
    }

    increaseProduct(i: number): void {
        this._cartService.increaseProduct(i)
    }

    decreaseProduct(i: number): void {
        this._cartService.decreaseProduct(i)
    }

    closeCart(): void {
        this._cartService.closeCart()
    }

    @HostListener('click')
    clickInside() {
        this.wasInside = true
    }

    @HostListener('document:click')
    clickout() {
        if (!this.showCart$) {
            return
        }
        if (!this.wasInside) {
            this._cartService.closeCart()
            this.wasInside = true
            return
        }
        this.wasInside = false
        
    }

    endShop(): void {
        this._cartService.closeCart()
        this._router.navigate(['/shopping-cart'])
    }
}
