import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Product } from "../models/product";
import { map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    private url: string;

    constructor(
        public _http: HttpClient
    ) {
        this.url = environment.prductApiUrl;
    }

    existUser(email: string) {
        let queryParams = new HttpParams().append("email", email);

        interface DataResponse {
            data: Product
        }

        return this._http.get<DataResponse>(this.url + 'find-by-email/v1', {
            params: queryParams,
            observe: 'response'
        })
    }
}