import { Component, Input } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "../../environments/environment";

@Component({
	selector: 'ngbd-modal-contact-us',
	templateUrl: './modal-contact-us.html',
	providers: [NgbModalConfig, NgbModal],
})
export class NgbdModalConfig {
	contact: string
	contactShow: string
	instagram: string
	facebook: string

	constructor(config: NgbModalConfig, private modalService: NgbModal) {
		this.contact = environment.contact
		this.contactShow = environment.contactShow
		this.instagram = environment.intagram
		this.facebook = environment.facebook
	}

	open(content: any) {
		this.modalService.open(content, {
			windowClass: 'dark-modal'
		})
	}
}