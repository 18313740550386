export class Categories {
    constructor(
        public title: string,
        public description: string,
        public categories: Category[],
    ) { }
}

export class Category {
    constructor(
        public name: string,
        public pathUrl: string,
        public imgUrl: string,
        public description: string,
        public longDescription: string,
        public type: string,
        public products: Product[],
        public discount: Discount,
        public status: boolean,
    ) { }
}

export class Product {
    constructor(
        public category: string,
        public name: string,
        public description: string,
        public pathUrl: string,
        public price: string,
        public specialPrice: string,
        public subTotalPrice: string,
        public iva: string,
        public position: string,
        public sizes: string[],
        public colors: Colors[],
        public img: string,
        public status: boolean,
        public discount: Discount,
    ) { }
}

export class Colors {
    constructor(
        public url: string,
        public name: string,
        public color: string,
        public colorTwo: string,
    ) { }
}

export class Discount {
    constructor(
        public category: string,
        public type: string,
        public value: number,
        public weight: number,
        public status: boolean,
    ) { }
}