import { Component, OnInit } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SeoService } from 'src/app/services/seo.service';

@Component({
    selector: 'app-catalog-pdf',
    templateUrl: './catalog-pdf.component.html',
    styleUrls: ['./catalog-pdf.component.css'],
    standalone: true,
    imports: [
        PdfViewerModule,
    ],
})
export class CatalogPdfComponent implements OnInit {
    pdfSource = "https://static.cantsu.com/pdf/catalog_v2.pdf";

    constructor(
        private _seoService: SeoService,
    ) { 
        window.scrollTo(0, 100)
    }

    ngOnInit(): void {
        this.createLinkForCanonicalURL()
    }
    
    createLinkForCanonicalURL() {
        this._seoService.createLinkForCanonicalURL()
    } 
}
